import React from "react";
//import * as S from "./styles";

const ModalTable = (props) => {
    return (
        <td
            className="modal fade"
            id={props.row.id}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            OM - {props.row.original.om}
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        {props.row.cells.map((cell) => {
                            return (
                                <p {...cell.getCellProps()}>
                                    <b>{cell.render("Header")}</b>
                                    {" - "}
                                    {cell.render("Cell")}
                                </p>
                            );
                        })}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Cancelar
                        </button>
                        <button type="button" className="btn btn-primary">
                            Finalizar
                        </button>
                    </div>
                </div>
            </div>
        </td>
    );
};

export default ModalTable;
