import styled from "styled-components";

export const ModalHeader = styled.div`
    .div-main-info-product {
        margin-top: -16px;
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: 30px;
    }

    .div-main-info-product .div-info-product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: auto;
        background-color: #00b3e4;
    }

    .div-main-info-product .div-info-product .nome {
        width: 100%;
    }

    .div-main-info-product .div-info-product .nome p {
        margin: 0;
        font-weight: bold;
        color: #fff;
        font-size: 0.9rem;
        padding: 3px 20px;
    }

    .div-main-info-product .div-info-product .validity {
        width: 100%;
    }

    .div-main-info-product .div-info-product .validity p {
        background-color: #e74c3c;
        color: #fff;
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 0;
        padding: 3px 20px;
    }

    @media screen and (min-width: 500px) {
        .div-main-info-product .div-info-product {
            flex-direction: row;
        }

        .div-main-info-product .div-info-product .validity {
            width: auto;
        }

        .div-main-info-product .div-info-product .validity p {
            width: auto;
            background-color: #e74c3c;
            color: #fff;
            font-size: 0.9rem;
            font-weight: bold;
            text-align: center;
            margin-bottom: 0;
            padding: 6px 10px;
        }

        .div-main-info-product .div-info-product .nome {
            margin-bottom: 0;
            width: auto;
        }
    }

    @media screen and (min-width: 900px) {
        .div-main-info-product .div-info-product {
            width: 100%;
            margin-bottom: 0;
        }

        .div-main-info-product .div-info-product .nome p {
            font-size: 1rem;
        }
    }
`;
