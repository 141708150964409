import React, { useState, useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import * as S from "./styles";
import LogoEyepharma from "../../assets/logo.png";

const LoginPage = () => {
    //Recuperando a data atual
    const date = new Date();

    const { login } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState("password");

    //Campos do formulário
    const [formData, setFormData] = useState({ email: "", password: "" });

    //Recebendo os dados do formulário
    const valueInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //Status da validação feita no formulário
    const [formValidateStatus, setFormValidateStatus] = useState({
        type: "",
        message: "",
    });

    //Validando o formulário
    function formValidate() {
        const patternForEmail = new RegExp(
            /^[a-zA-Z0-9_.-]+@+[a-zA-Z0-9_.-]+$/
        );

        if (!patternForEmail.test(formData.email)) {
            return setFormValidateStatus({
                type: "Erro",
                message: (
                    <>
                        <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                        Digite um <strong>E-mail</strong> válido!
                    </>
                ),
            });
        }

        if (formData.password.length < 6) {
            return setFormValidateStatus({
                type: "Erro",
                message: (
                    <>
                        <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                        Sua <strong>senha</strong> precisa ter no mínimo 6
                        caracteres.
                    </>
                ),
            });
        }

        return true;
    }

    //Lidando com o submit do formulário
    const handleSumit = async (e) => {
        e.preventDefault();

        //Validando o formulário local
        if (!formValidate()) {
            return;
        } else {
            setLoading(true);

            //Consultando as credenciais na API
            const response = await login(formData.email, formData.password); //Integração com meu Context /Api

            //Verifica se retornou erro da API, se sim apresenta na tela.
            if (response) {
                if (response.response) {
                    setFormValidateStatus({
                        type: "Erro",
                        message: (
                            <>
                                <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                                {response.response.data.message}
                            </>
                        ),
                    });
                } else {
                    setFormValidateStatus({
                        type: "Erro",
                        message: (
                            <>
                                <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                                {response.message}
                            </>
                        ),
                    });
                }
            }

            setLoading(false);
        }
    };

    return (
        <S.LoginPage>
            <div className="div-form">
                <form className="w-100" onSubmit={handleSumit}>
                    <img
                        className="mb-4 w-100"
                        src={LogoEyepharma}
                        alt="Logo Eye Pharma"
                    />
                    <div className="form-floating mb-3">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={valueInput}
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            required
                        />
                        <label htmlFor="floatingInput">E-mail</label>
                    </div>
                    <div className="form-floating">
                        <input
                            type={showPassword}
                            name="password"
                            value={formData.password}
                            onChange={valueInput}
                            className="form-control"
                            id="floatingPassword"
                            placeholder="Password"
                            required
                        />
                        <label htmlFor="floatingPassword">Senha</label>

                        <span
                            className="btn-show-password"
                            onClick={(e) => {
                                e.preventDefault();

                                if (showPassword === "password") {
                                    setShowPassword("text");
                                } else {
                                    setShowPassword("password");
                                }
                            }}
                        >
                            <i
                                className={
                                    showPassword === "password"
                                        ? "bi bi-eye-slash"
                                        : "bi bi-eye"
                                }
                            ></i>{" "}
                        </span>
                    </div>

                    {formValidateStatus.type === "Erro" && (
                        <div
                            className="alert alert-danger mt-3 mb-0"
                            role="alert"
                        >
                            {formValidateStatus.message}
                        </div>
                    )}

                    {loading ? (
                        <button
                            type="submit"
                            className="btn btn-lg btn-primary btb-sigin mt-4 w-100"
                            disabled
                        >
                            Entrar{" "}
                            <span
                                className="spinner-border spinner-border-sm ms-1"
                                aria-hidden="true"
                            ></span>
                        </button>
                    ) : (
                        <button
                            type="submit"
                            className="btn btn-lg btn-primary btb-sigin mt-4 w-100"
                        >
                            Entrar{" "}
                        </button>
                    )}
                </form>

                <div className="div-copyright">
                    <p className="text-muted copyright">
                        © {date.getFullYear()} Eye Pharma LTDA - Todos os
                        direitos reservados.
                    </p>
                </div>
            </div>
        </S.LoginPage>
    );
};

export default LoginPage;
