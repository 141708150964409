import moment from "moment";
import { ColumnFilter } from "./columnFilter";

export const COLUMNS = [
    {
        Header: "Data",
        accessor: "data",
        Cell: (props) => {
            const formatedDate = moment(props.value).format("DD/MM/yyyy");
            return <span>{formatedDate}</span>;
        },
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: "Horário",
        accessor: "hora",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "Temp_Atual",
        accessor: "tempAtual",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + " °C";
            return <span>{value}</span>;
        },
    },
    {
        Header: "Temp_Min",
        accessor: "tempMin",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + " °C";
            return <span>{value}</span>;
        },
    },
    {
        Header: "Temp_Max",
        accessor: "tempMax",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + " °C";
            return <span>{value}</span>;
        },
    },
    {
        Header: "Pressão",
        accessor: "pressao",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "UR_Atual",
        accessor: "umiAtual",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + " %";
            return <span>{value}</span>;
        },
    },
    {
        Header: "UR_Min",
        accessor: "umiMin",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + " %";
            return <span>{value}</span>;
        },
    },
    {
        Header: "UR_Max",
        accessor: "umiMax",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + " %";
            return <span>{value}</span>;
        },
    },
    // {
    //     Header: "Prioridade",
    //     accessor: "urgente",
    //     Cell: (props) => {
    //         let status = "";
    //         if (Number(props.value) === 0) {
    //             status = "Normal";
    //         } else {
    //             status = "Urgente";
    //         }
    //         return <span>{status}</span>;
    //     },
    //     Filter: ColumnFilter,
    //     disableFilters: true,
    // },
];
