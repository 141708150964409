import styled from "styled-components";

export const Headder = styled.div`
    box-shadow: rgba(149, 157, 165, 0.2) 0px 5px 10px;
    z-index: 1001;

    .logo-short {
        width: 49px;
        margin-top: -10px;
    }

    .logo-name-user {
        font-size: 1.25rem;
    }
`;
