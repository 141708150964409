import React, { useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    useFilters,
    usePagination,
    useColumnOrder,
} from "react-table";
import OptionsColumns from "./ColumnsOptions";
import ModalTableDetailsProd from "./Modal";
import { COLUMNS, GROUPED_COLUMNS } from "./Columns/Columns";
import * as XLSX from "xlsx";
import * as S from "./styles";

const PcmTable = (props) => {
    const columns = useMemo(() => COLUMNS, []);
    //const columns = useMemo(() => GROUPED_COLUMNS, []);
    const data = useMemo(() => props.Products, []);

    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (index) => {
        setSelectedRow(index);
    };

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: columns.map((column) => {
                    if (column.show === false)
                        return column.accessor || column.id;
                }),
                pageSize: 50,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useColumnOrder
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        allColumns,
        getToggleHideAllColumnsProps,
        setColumnOrder,
        setGlobalFilter,
    } = tableInstance;

    const { pageIndex, pageSize } = state;

    const { globalFilter } = state;

    const downloadExcel = (dataDownload, fileName) => {
        //Formatando os dados para download do excel
        for (let i = 0; i < dataDownload.length; i++) {
            //Adiciona o simbolo %
            dataDownload[i].percvenda = dataDownload[i].percvenda + "%";

            //convertendo data para o pardão diames/ano
            dataDownload[i].dataprevestoque = dataDownload[i].dataprevestoque
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/");
            dataDownload[i].datarepo = dataDownload[i].datarepo
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/");
        }

        const newData = dataDownload.map((row) => {
            delete row.tableData;
            return row;
        });
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "students");
        //Buffer
        //let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download

        if (fileName === "lotes") {
            XLSX.writeFile(workBook, "Lotes.xlsx");
        } else if (fileName === "ordens-prev") {
            XLSX.writeFile(workBook, "Ordens-Previstas.xlsx");
        } else if (fileName === "ordens-prod") {
            XLSX.writeFile(workBook, "Ordens-Produzidas.xlsx");
        } else {
            XLSX.writeFile(workBook, "Data-Produtos.xlsx");
        }
    };

    return (
        <S.PcmTable>
            {data !== "" && (
                <div>
                    <OptionsColumns
                        allColumns={allColumns}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        getToggleHideAllColumnsProps={
                            getToggleHideAllColumnsProps
                        }
                        nextPage={nextPage}
                        previousPage={previousPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageOptions={pageOptions}
                        gotoPage={gotoPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        downloadExcel={downloadExcel}
                        data={data}
                    />

                    <div className="table-responsive div-table">
                        <table className="table table-pcm" {...getTableProps()}>
                            <thead>
                                <tr className="tr-columns-group">
                                    <th
                                        className="text-center bg-color-product"
                                        colSpan="4"
                                    >
                                        Informações do Produto
                                    </th>
                                    <th
                                        className="text-center bg-color-lots"
                                        colSpan="5"
                                    >
                                        Informações dos Lotes
                                    </th>
                                    <th
                                        className="text-center bg-color-expedition"
                                        colSpan="2"
                                    >
                                        Expedição
                                    </th>
                                    <th
                                        className="text-center bg-color-balances"
                                        colSpan="1"
                                    >
                                        Saldos
                                    </th>
                                    <th
                                        className="text-center bg-color-calculations"
                                        colSpan="5"
                                    >
                                        Cálculos PCM
                                    </th>
                                    <th
                                        className="text-center bg-color-details"
                                        colSpan="1"
                                    ></th>
                                </tr>

                                {headerGroups.map((headerGroup) => (
                                    <tr
                                        className="tr-columns"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                className={
                                                    column.id === "codigo" ||
                                                    column.id === "nome" ||
                                                    column.id === "und" ||
                                                    column.id === "validade"
                                                        ? "bg-color-product"
                                                        : column.id ===
                                                              "bloq" ||
                                                          column.id ===
                                                              "qtprevista" ||
                                                          column.id ===
                                                              "qtproducao" ||
                                                          column.id ===
                                                              "disponivel" ||
                                                          column.id === "qt"
                                                        ? "bg-color-lots"
                                                        : column.id ===
                                                              "qtpend" ||
                                                          column.id ===
                                                              "qtreserv"
                                                        ? "bg-color-expedition"
                                                        : column.id ===
                                                          "qtgeral"
                                                        ? "bg-color-balances"
                                                        : column.id === "m23" ||
                                                          column.id ===
                                                              "calc23" ||
                                                          column.id ===
                                                              "percvenda" ||
                                                          column.id ===
                                                              "dataprevestoque" ||
                                                          column.id ===
                                                              "datarepo"
                                                        ? "bg-color-calculations"
                                                        : column.id ===
                                                          "detalhes"
                                                        ? "bg-color-details"
                                                        : ""
                                                }
                                                key={column.id}
                                            >
                                                <span className="thead-div-arrow">
                                                    <span className="text-black-50">
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </span>

                                                    <span
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                    >
                                                        {!column.disableSortBy ? (
                                                            <span>
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <i className="bi bi-chevron-down ms-3 text-black" />
                                                                    ) : (
                                                                        <i className="bi bi-chevron-up ms-3 text-black" />
                                                                    )
                                                                ) : (
                                                                    <span className="div-thead-arrow-up-down text-black">
                                                                        <i className="bi bi-chevron-up ms-3" />
                                                                        <i className="bi bi-chevron-down ms-3" />
                                                                    </span>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </span>
                                                </span>

                                                <span>
                                                    {column.canFilter
                                                        ? column.render(
                                                              "Filter"
                                                          )
                                                        : null}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {page.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            key={index}
                                            className={
                                                selectedRow === index
                                                    ? "selected-row"
                                                    : ""
                                            }
                                            onClick={() =>
                                                handleRowClick(index)
                                            }
                                        >
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td
                                                        className={
                                                            cell.column.id ===
                                                                "codigo" ||
                                                            cell.column.id ===
                                                                "nome" ||
                                                            cell.column.id ===
                                                                "und" ||
                                                            cell.column.id ===
                                                                "validade"
                                                                ? "bg-color-product-light"
                                                                : cell.column
                                                                      .id ===
                                                                      "bloq" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "qtprevista" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "qtproducao" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "disponivel" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "qt"
                                                                ? "bg-color-lots-light"
                                                                : cell.column
                                                                      .id ===
                                                                      "qtpend" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "qtreserv"
                                                                ? "bg-color-expedition-light"
                                                                : cell.column
                                                                      .id ===
                                                                  "qtgeral"
                                                                ? "bg-color-balances-light"
                                                                : cell.column
                                                                      .id ===
                                                                      "m23" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "calc23" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "percvenda" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "dataprevestoque" ||
                                                                  cell.column
                                                                      .id ===
                                                                      "datarepo"
                                                                ? "bg-color-calculations-light"
                                                                : cell.column
                                                                      .id ===
                                                                  "detalhes"
                                                                ? "bg-color-details-light"
                                                                : ""
                                                        }
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/***Carregamento dos modais***/}
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <ModalTableDetailsProd
                                downloadExcel={downloadExcel}
                                row={row}
                                key={"detail" + row.id}
                            />
                        );
                    })}
                </div>
            )}

            <Tooltip id="my-tooltip" />
        </S.PcmTable>
    );
};

export default PcmTable;
