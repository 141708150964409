import styled from "styled-components";

export const Filters = styled.div`
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1000;

    .div-filters-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: #dae9f8;
        border: 1px solid #dee2e6;
    }

    .div-filters-header h1 {
        color: #333;
        font-size: 1rem;
        margin: 0;
    }

    // .div-filters-header .div-btn-filters .btn-filters {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: flex-start;
    //     align-items: center;
    //     background-color: #fff;
    //     color: #999;
    //     font-weight: bold;
    //     border: none;
    //     transition: 0.3s;
    // }

    // .div-filters-header .div-btn-filters .btn-filters:hover {
    //     color: #333;
    //     background-color: #eee;
    // }

    // .div-filters-header .div-btn-filters .btn-filters i {
    //     font-size: 0.9rem;
    // }

    .offcanvas {
        padding-top: 60px;
    }

    .offcanvas-body {
        background-color: #f0f5fa;
    }

    .div-filters {
        border-radius: 7px;
        padding: 10px;
        background-color: #fff;
    }
`;
