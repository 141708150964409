import styled from "styled-components";

export const Modal = styled.div`
    .nav-modal-details .nav-link {
        color: #aaa;
    }

    .nav-modal-details .nav-link:hover {
        color: #555;
    }

    .nav-modal-details .nav-link.active {
        color: #000;
        font-weight: bold;
    }

    .div-main-charts-produto {
        padding: 30px;
        border: 1px solid #777;
        border-radius: 7px;
    }

    .div-main-content {
        padding-top: 30px;
        // border: 1px solid #ddd;
        // border-radius: 7px;
    }
`;
