import React from "react";
import { useLocation } from "react-router-dom";
import Headder from "../../components/Headder";
import { Link } from "react-router-dom";
import * as S from "./styles";

const HomePage = () => {
    const location = useLocation();

    return (
        <div className="container">
            <S.HomePage>
                <Headder />

                {location.state?.message && (
                    <div className="div-cards-alert alert alert-danger">
                        <i className="bi bi-exclamation-triangle-fill"></i>
                        &nbsp;
                        {location.state.message}
                    </div>
                )}

                <div className="div-cards">
                    <Link to="/pcm" className="card">
                        <i className="bi bi-calendar3"></i>
                        <h2>PCM</h2>
                    </Link>
                    <Link to="/manip" className="card">
                        <i className="bi bi-eyedropper"></i>
                        <h3>Manip</h3>
                    </Link>
                    <Link to="/areaLog" className="card">
                        <i className="bi bi-thermometer-half"></i>
                        <h3>Área Log</h3>
                    </Link>
                </div>
            </S.HomePage>
        </div>
    );
};

export default HomePage;
