import React from "react";
//import * as S from "./styles";

const ModalTable = () => {
    return (
        <td
            className="modal fade"
            id="areaLogModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            Registro de informações
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <select
                            className="form-select form-select mb-3"
                            aria-label=".form-select-lg example"
                        >
                            <option value="sala1">Sala 1</option>
                            <option value="sala2">Sala 2</option>
                            <option value="sala3">Sala 3</option>
                            <option value="sala4">Sala 4</option>
                        </select>

                        <input
                            className="form-control mb-3"
                            type="date"
                            placeholder="Data"
                        />

                        <input
                            className="form-control mb-3"
                            type="time"
                            placeholder="Data Inicial"
                        />
                        <input
                            className="form-control mb-3"
                            type="text"
                            placeholder="Temperatura Atual"
                        />
                        <input
                            className="form-control mb-3"
                            type="text"
                            placeholder="Temperatura Min"
                        />
                        <input
                            className="form-control mb-3"
                            type="text"
                            placeholder="Temperatura Max"
                        />
                        <input
                            className="form-control mb-3"
                            type="text"
                            placeholder="Pressão"
                        />
                        <input
                            className="form-control mb-3"
                            type="text"
                            placeholder="UR Atual"
                        />
                        <input
                            className="form-control mb-3"
                            type="text"
                            placeholder="UR Min"
                        />
                        <input
                            className="form-control mb-3"
                            type="text"
                            placeholder="UR Max"
                        />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Cancelar
                        </button>
                        <button type="button" className="btn btn-primary">
                            Gravar
                        </button>
                    </div>
                </div>
            </div>
        </td>
    );
};

export default ModalTable;
