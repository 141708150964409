import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { getOps } from "../../services/api";
import Headder from "../../components/Headder";
import Op from "../../components/TableManip/Op";
import Loading from "../../components/Loading";
import TableManip from "../../components/TableManip";
import * as S from "./styles";

const ManipPage = () => {
    const [Ops, setOps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [Filial, setFilial] = useState(6);
    const [HideTable, setHideTable] = useState("hide-component");
    const [HideCards, setHideCards] = useState("");
    const { User } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            //Verrifica se o usuário podo acessar a página
            if (
                User.email === "ronaldo.santos@eyepharma.com.br" ||
                User.email === "gleice.teixeira@eyepharma.com.br"
            ) {
                navigate("/", {
                    state: {
                        message:
                            "Você não tem permissão para acessar esse módulo.",
                    },
                });
            } else {
                const response = await getOps(Filial);
                setOps(response);
                setLoading(false);
            }
        })();
    }, [Filial]);

    function changeViewsForTable() {
        setHideTable("");
        setHideCards("hide-component");
    }

    function changeViewsForCard() {
        setHideTable("hide-component");
        setHideCards("");
    }

    return (
        <S.ManipPage>
            {loading !== false && <Loading className="loading" />}
            <Headder />
            <main className="main-home mb-5">
                {/* <h1 className="mb-4 titulo-home">
                    ORDENS <i className="bi bi-eyedropper"></i>
                </h1> */}

                <div className="div-view-type mb-4">
                    <select
                        className="form-select form-select me-3"
                        aria-label=".form-select-lg example"
                        value={Filial}
                        onChange={(e) => {
                            setFilial(e.target.value);
                            setLoading(true);
                        }}
                    >
                        <option value="6">Filial 6</option>
                        <option value="7">Filial 7</option>
                    </select>

                    <button
                        className="btn btn-outline-secondary me-2"
                        onClick={changeViewsForCard}
                    >
                        <i className="bi bi-grid-3x3-gap-fill"></i>
                    </button>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={changeViewsForTable}
                    >
                        <i className="bi bi-table"></i>
                    </button>
                </div>

                {loading !== true && (
                    //Cards
                    <div className={HideCards}>
                        <div className="row">
                            {Ops.data.ordens && Ops.data.ordens.length !== 0 ? (
                                Ops.data.ordens.map((op) => (
                                    <Op
                                        cliente={op.cliente}
                                        crm={op.crm}
                                        data={op.data}
                                        embalagem={op.embalagem}
                                        idrec={op.idrec}
                                        medico={op.medico}
                                        om={op.om}
                                        paciente={op.paciente}
                                        pedido={op.pedido}
                                        principioAtivo={op.principioativo}
                                        qtd={op.qtd}
                                        urgente={op.urgente}
                                        key={op.om}
                                    />
                                ))
                            ) : Ops.message ? (
                                <div
                                    className="alert alert-danger ms-2"
                                    role="alert"
                                >
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                    {"  "}
                                    {Ops.message}
                                </div>
                            ) : (
                                <div
                                    className="alert alert-warning ms-2"
                                    role="alert"
                                >
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                    {"  "}
                                    Não existe <b>OM</b> para essa Filial.
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {loading !== true && (
                    //Table
                    <div className={HideTable}>
                        <div className="w-100">
                            {Ops.data.ordens && Ops.data.ordens.length !== 0 ? (
                                <TableManip ops={Ops.data.ordens} />
                            ) : Ops.message ? (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                    {"  "}
                                    {Ops.message}
                                </div>
                            ) : (
                                <div
                                    className="alert alert-warning"
                                    role="alert"
                                >
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                    {"  "}
                                    Não existe <b>OM</b> para essa Filial.
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </main>
        </S.ManipPage>
    );
};

export default ManipPage;
