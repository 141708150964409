import styled from "styled-components";

export const AreaLogPage = styled.div`
    .main-area-log {
        margin-left: 310px;
        padding-right: 10px;
        overflow: hidden;
        margin-top: 100px;
        margin-bottom: 30px;
        transition: margin 0.4s;
    }

    .main-area-log .div-filters {
        display: flex;
        flex-direction: row;
    }

    .main-area-log .div-filters select {
        width: 180px;
    }

    @media (width < 1368px) {
        .main-area-log {
            margin-left: 90px;
        }
    }

    // @media (width < 1200px) {
    // .main-area-log {
    //     margin-left: auto;
    //     padding-right: 0;
    // }
`;
