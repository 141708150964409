import React from "react";
import * as S from "./styles";

const ModalLoading = () => {
    return (
        <S.ModalLoading>
            <div className="spinner-grow text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-info" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </S.ModalLoading>
    );
};

export default ModalLoading;
