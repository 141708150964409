import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { getOps } from "../../services/api";
import Headder from "../../components/Headder";
import Loading from "../../components/Loading";
import TableAreaLog from "../../components/TableAreaLog";
import * as S from "./styles";
import SideBarAreaLog from "../../components/SideBarAreaLog";

const AreaLogPage = () => {
    const [dataLog, setDataLog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [Filial, setFilial] = useState(6);
    const { User } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            //Verrifica se o usuário podo acessar a página
            if (User.email === "ronaldo.santos@eyepharma.com.br") {
                navigate("/", {
                    state: {
                        message:
                            "Você não tem permissão para acessar esse módulo.",
                    },
                });
            } else {
                //const response = await getOps(Filial);

                const response = [
                    {
                        data: "2024-09-01 00:00:00",
                        hora: "10:30",
                        tempAtual: "20",
                        tempMin: "18",
                        tempMax: "24",
                        pressao: "15",
                        umiAtual: "60",
                        umiMin: "50",
                        umiMax: "75",
                    },
                    {
                        data: "2024-09-02 01:00:00",
                        hora: "11:00",
                        tempAtual: "21",
                        tempMin: "19",
                        tempMax: "25",
                        pressao: "16",
                        umiAtual: "62",
                        umiMin: "51",
                        umiMax: "76",
                    },
                    {
                        data: "2024-09-03 01:30:00",
                        hora: "11:30",
                        tempAtual: "22",
                        tempMin: "20",
                        tempMax: "26",
                        pressao: "16",
                        umiAtual: "63",
                        umiMin: "52",
                        umiMax: "77",
                    },
                    {
                        data: "2024-09-04 02:00:00",
                        hora: "12:00",
                        tempAtual: "23",
                        tempMin: "21",
                        tempMax: "27",
                        pressao: "17",
                        umiAtual: "64",
                        umiMin: "53",
                        umiMax: "78",
                    },
                    {
                        data: "2024-09-05 02:30:00",
                        hora: "12:30",
                        tempAtual: "24",
                        tempMin: "22",
                        tempMax: "28",
                        pressao: "17",
                        umiAtual: "65",
                        umiMin: "54",
                        umiMax: "79",
                    },
                    {
                        data: "2024-09-06 03:00:00",
                        hora: "13:00",
                        tempAtual: "25",
                        tempMin: "23",
                        tempMax: "29",
                        pressao: "18",
                        umiAtual: "66",
                        umiMin: "55",
                        umiMax: "80",
                    },
                    {
                        data: "2024-09-07 03:30:00",
                        hora: "13:30",
                        tempAtual: "26",
                        tempMin: "24",
                        tempMax: "30",
                        pressao: "18",
                        umiAtual: "67",
                        umiMin: "56",
                        umiMax: "81",
                    },
                    {
                        data: "2024-09-08 04:00:00",
                        hora: "14:00",
                        tempAtual: "27",
                        tempMin: "25",
                        tempMax: "31",
                        pressao: "19",
                        umiAtual: "68",
                        umiMin: "57",
                        umiMax: "82",
                    },
                    {
                        data: "2024-09-09 04:30:00",
                        hora: "14:30",
                        tempAtual: "28",
                        tempMin: "26",
                        tempMax: "32",
                        pressao: "19",
                        umiAtual: "69",
                        umiMin: "58",
                        umiMax: "83",
                    },
                    {
                        data: "2024-09-10 05:00:00",
                        hora: "15:00",
                        tempAtual: "29",
                        tempMin: "27",
                        tempMax: "33",
                        pressao: "20",
                        umiAtual: "70",
                        umiMin: "59",
                        umiMax: "84",
                    },
                    {
                        data: "2024-09-11 05:30:00",
                        hora: "15:30",
                        tempAtual: "30",
                        tempMin: "28",
                        tempMax: "34",
                        pressao: "20",
                        umiAtual: "71",
                        umiMin: "60",
                        umiMax: "85",
                    },
                    {
                        data: "2024-09-12 06:00:00",
                        hora: "16:00",
                        tempAtual: "31",
                        tempMin: "29",
                        tempMax: "35",
                        pressao: "21",
                        umiAtual: "72",
                        umiMin: "61",
                        umiMax: "86",
                    },
                    {
                        data: "2024-09-13 06:30:00",
                        hora: "16:30",
                        tempAtual: "32",
                        tempMin: "30",
                        tempMax: "36",
                        pressao: "21",
                        umiAtual: "73",
                        umiMin: "62",
                        umiMax: "87",
                    },
                    {
                        data: "2024-09-14 07:00:00",
                        hora: "17:00",
                        tempAtual: "33",
                        tempMin: "31",
                        tempMax: "37",
                        pressao: "22",
                        umiAtual: "74",
                        umiMin: "63",
                        umiMax: "88",
                    },
                    {
                        data: "2024-09-15 07:30:00",
                        hora: "17:30",
                        tempAtual: "34",
                        tempMin: "32",
                        tempMax: "38",
                        pressao: "22",
                        umiAtual: "75",
                        umiMin: "64",
                        umiMax: "89",
                    },
                    {
                        data: "2024-09-16 08:00:00",
                        hora: "18:00",
                        tempAtual: "35",
                        tempMin: "33",
                        tempMax: "39",
                        pressao: "23",
                        umiAtual: "76",
                        umiMin: "65",
                        umiMax: "90",
                    },
                    {
                        data: "2024-09-17 08:30:00",
                        hora: "18:30",
                        tempAtual: "36",
                        tempMin: "34",
                        tempMax: "40",
                        pressao: "23",
                        umiAtual: "77",
                        umiMin: "66",
                        umiMax: "91",
                    },
                    {
                        data: "2024-09-18 09:00:00",
                        hora: "19:00",
                        tempAtual: "37",
                        tempMin: "35",
                        tempMax: "41",
                        pressao: "24",
                        umiAtual: "78",
                        umiMin: "67",
                        umiMax: "92",
                    },
                    {
                        data: "2024-09-19 09:30:00",
                        hora: "19:30",
                        tempAtual: "38",
                        tempMin: "36",
                        tempMax: "42",
                        pressao: "24",
                        umiAtual: "79",
                        umiMin: "68",
                        umiMax: "93",
                    },
                    {
                        data: "2024-09-20 10:00:00",
                        hora: "20:00",
                        tempAtual: "39",
                        tempMin: "37",
                        tempMax: "43",
                        pressao: "25",
                        umiAtual: "80",
                        umiMin: "69",
                        umiMax: "94",
                    },
                ];

                setDataLog(response);
                setLoading(false);
            }
        })();
    }, [Filial]);

    return (
        <S.AreaLogPage>
            {loading !== false && <Loading className="loading" />}
            <SideBarAreaLog />
            <Headder />
            <main className="main-area-log mb-5">
                <div className="div-filters mb-4">
                    {/* <select
                        className="form-select form-select me-3"
                        aria-label=".form-select-lg example"
                        value={Filial}
                        onChange={(e) => {
                            setFilial(e.target.value);
                            setLoading(true);
                        }}
                    >
                        <option value="6">Filial 6</option>
                        <option value="7">Filial 7</option>
                    </select> */}

                    <select
                        className="form-select form-select me-3"
                        aria-label=".form-select-lg example"
                    >
                        <option value="sala1">Sala 1</option>
                        <option value="sala2">Sala 2</option>
                        <option value="sala3">Sala 3</option>
                        <option value="sala4">Sala 4</option>
                    </select>

                    <div className="d-flex align-items-center">
                        <span className="me-3">
                            <b>Período:</b>
                        </span>
                        <input
                            className="form-control me-3"
                            type="date"
                            placeholder="Data Inicial"
                        />
                        <span className="me-3">até</span>
                        <input
                            className="form-control me-3"
                            type="date"
                            placeholder="Data final"
                        />
                    </div>
                    <button type="button" className="btn btn-primary">
                        <i className="bi bi-search"></i> Pesquisar
                    </button>
                </div>

                {loading !== true && (
                    //Table
                    <div>
                        <div className="w-100">
                            {dataLog && dataLog.length !== 0 ? (
                                <TableAreaLog dataLog={dataLog} />
                            ) : dataLog.message ? (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                    {"  "}
                                    {dataLog.message}
                                </div>
                            ) : (
                                <div
                                    className="alert alert-warning"
                                    role="alert"
                                >
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                    {"  "}
                                    Não existe <b>OM</b> para essa Filial.
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </main>
        </S.AreaLogPage>
    );
};

export default AreaLogPage;
