import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { getProducts, checkTokenValidity, apiBase } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Headder from "../../components/Headder";
import Filters from "../../components/TablePcm/Filters";
import TablePlaceholder from "../../components/TablePcm/TablePlaceholder";
import TablePcm from "../../components/TablePcm";
import * as S from "./styles";

const PcmPage = () => {
    const [ProductsBase, setProductsBase] = useState([]);
    const [Products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [FilterAplly, setFilterAplly] = useState(false);
    const [ClearFilter, setClearFilter] = useState(false);
    const [Errors, setErrors] = useState(false);
    const [classError, setClassError] = useState(
        "alert alert-danger my-alert d-none"
    );
    const [classErrorLocal, setClassErrorLocal] = useState(
        "alert alert-light my-alert d-none"
    );
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const { User } = useContext(AuthContext);

    //Objeto de filtros com valores padrão
    const [FiltersObject, setFiltersObject] = useState({
        branch: "",
        productValidity: "",
        productsBlockedLots: false,
        ordersInProduction: false,
        availableStockSmallerAverageDay23: false,
        pendingInventoryBiggerOrEqualGeneralInventory: false,
        minimumQuantityAvailable: "",
        maximumQuantityAvailable: "",
    });

    //Verrifica se o usuário podo acessar a página
    useEffect(() => {
        if (User.email === "gleice.teixeira@eyepharma.com.br") {
            navigate("/", {
                state: {
                    message: "Você não tem permissão para acessar esse módulo.",
                },
            });
        }
    }, []);

    //Busca os Products.
    const updateProducts = () => {
        (async () => {
            //Verificar a validade do Token
            const tokenExpired = checkTokenValidity();

            if (tokenExpired) {
                localStorage.removeItem("userName");
                localStorage.removeItem("token");
                apiBase.defaults.headers.Authorization = null;
                setUser(null);
                navigate("/login");
            } else {
                const response = await getProducts();

                if (response.status === 200) {
                    setProductsBase(response.data.produtos);
                    setProducts(response.data.produtos);
                    setClassError("alert alert-danger my-alert d-none");
                    setClassErrorLocal("alert alert-light my-alert d-none");
                    setLoading(false);
                } else {
                    //Lidando com erros
                    console.log(response);
                    setProductsBase([]);
                    setProducts([]);
                    setErrors(true);
                    setClassError("alert alert-danger my-alert d-block");
                    setClassErrorLocal("alert alert-light my-alert d-none");
                    setLoading(false);
                }
            }
        })();
    };

    useEffect(() => {
        updateProducts();
    }, []);

    //Filtra o Array atual dos Produtos conforme a escolha do usuário.
    useEffect(() => {
        //console.log("Precisa aplicar o filtro!");

        if (FilterAplly === true) {
            let filtroFilial = 0;
            let filtroValidade = Infinity;
            let filtroLotesBloqueados = Infinity;
            let filtroQtDisponivel = 0;
            let filtroM23 = Infinity;
            let filtroQtPendente = Infinity;
            let filtroQtGeral = Infinity;
            let filtroOrdensEmProducao = Infinity;
            let filtroMinDisponivel = 0;
            let filtroMaxDisponivel = Infinity;

            // Filtrar os Products com base nos critérios selecionados
            const produtosFiltrados = Products.filter((produto) => {
                let validade = produto.validade;
                let qtDisponivel = produto.disponivel;
                let filial = produto.filial;

                //Verifica se o filtro foi ativado
                if (FiltersObject.branch !== "") {
                    filtroFilial = FiltersObject.branch;
                } else {
                    filtroFilial = produto.filial;
                }

                //Verifica se o filtro foi ativado
                if (FiltersObject.productValidity !== "") {
                    filtroValidade = Number(FiltersObject.productValidity);
                } else {
                    filtroValidade = Number(produto.validade);
                }

                //Verifica se o filtro foi ativado
                if (FiltersObject.productsBlockedLots) {
                    filtroLotesBloqueados = produto.bloq;
                }

                //Verifica se o filtro foi ativado
                if (FiltersObject.ordersInProduction) {
                    filtroOrdensEmProducao = produto.qtproducao;
                }

                //Verifica se o filtro foi ativado
                if (FiltersObject.availableStockSmallerAverageDay23) {
                    filtroQtDisponivel = produto.disponivel;
                    filtroM23 = produto.m23;
                }

                //Verifica se o filtro foi ativado
                if (
                    FiltersObject.pendingInventoryBiggerOrEqualGeneralInventory
                ) {
                    filtroQtPendente = produto.qtpend;
                    filtroQtGeral = produto.qtgeral;
                }

                //Verifica se o filtro foi ativado
                if (FiltersObject.minimumQuantityAvailable !== "") {
                    filtroMinDisponivel = Number(
                        FiltersObject.minimumQuantityAvailable
                    );
                }

                //Verifica se o filtro foi ativado
                if (FiltersObject.maximumQuantityAvailable !== "") {
                    filtroMaxDisponivel = Number(
                        FiltersObject.maximumQuantityAvailable
                    );
                }

                return (
                    filial === filtroFilial &&
                    filtroQtDisponivel < filtroM23 &&
                    filtroQtPendente >= filtroQtGeral &&
                    validade === filtroValidade &&
                    filtroLotesBloqueados > 0 &&
                    filtroOrdensEmProducao > 0 &&
                    qtDisponivel >= filtroMinDisponivel &&
                    qtDisponivel <= filtroMaxDisponivel
                );
            });

            if (produtosFiltrados.length === 0) {
                console.log(produtosFiltrados);
                setClassErrorLocal("alert alert-light my-alert d-block");
            }

            setProducts(produtosFiltrados);
            setLoading(false);
            setFilterAplly(false);
        }
    }, [FilterAplly]);

    //Limpar o filtro aplicado pelo usuário
    useEffect(() => {
        if (ClearFilter === true) {
            setProducts(ProductsBase);
            setLoading(false);
            setClearFilter(false);
        }
    }, [ClearFilter]);

    return (
        <S.PcmPage>
            <Headder />
            <main>
                <Filters
                    setLoading={setLoading}
                    setFilters={setFiltersObject}
                    setFilterAplly={setFilterAplly}
                    Filters={FiltersObject}
                    setClearFilter={setClearFilter}
                    setProducts={setProducts}
                    ProductsBase={ProductsBase}
                    updateProducts={updateProducts}
                />

                {loading !== true && (
                    //Table
                    <div className="w-100">
                        {Products && Products.length && (
                            <TablePcm Products={Products} />
                        )}

                        {Products && Products.length === 0 && (
                            <div className={classErrorLocal} role="alert">
                                <i className="bi bi-exclamation-triangle-fill me-1"></i>
                                {"  "}
                                <strong>
                                    Não foi possível encontrar resultados!
                                </strong>{" "}
                                Tente mudar os <strong>Filtros</strong>.
                            </div>
                        )}

                        {Errors && (
                            <div className={classError} role="alert">
                                <i className="bi bi-exclamation-triangle-fill me-1"></i>
                                {"  "}
                                Ocorreu uma <strong>falha</strong> ao carregar
                                as informações! Por favor contatar o{" "}
                                <strong>administrador</strong>.
                            </div>
                        )}
                    </div>
                )}

                {loading !== false && <TablePlaceholder />}
            </main>
        </S.PcmPage>
    );
};

export default PcmPage;
