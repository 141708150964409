import styled from "styled-components";

export const Table = styled.div`
    .div-show-columns {
        display: flex;
        flex-wrap: wrap;
    }

    .div-show-columns .form-check {
        margin-right: 20px;
    }

    .div-show-columns .form-check label {
        font-size: 0.825rem;
    }

    .div-table-scroll {
        height: 500px;
        overflow-y: scroll;
        margin-bottom: 30px;
    }

    table thead th {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
    }

    table thead tr th .thead-div-arrow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    table thead tr th .thead-div-arrow i {
        font-size: 0.6rem;
        //color: #000;
    }

    table thead tr th .div-thead-arrow-up-down {
        display: flex;
        flex-direction: column;
    }

    table thead tr th .div-thead-arrow-up-down i {
        margin-bottom: -7px;
        color: #000;
    }

    table tbody tr {
        cursor: pointer;
    }
`;
