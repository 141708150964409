import styled from "styled-components";

export const Avarages = styled.div`
    .cards {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .my-card {
        width: 100%;
        margin: 0 30px 30px 0;
        border: none;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
        transition: 0.3s;
    }

    .my-card:hover {
        box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.6);
    }

    .my-card .list-group .list-group-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;
        border: none;
        border-radius: 7px;
        padding-top: 5px;
    }

    .my-card .list-group .card-li-title {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

    .my-card .list-group .card-li-title .card-title {
        color: #252f4a;
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0;
    }

    .my-card .list-group .card-li-title .card-sub-title {
        color: #99a1b7;
        font-size: 1rem;
        font-weight: 600;
    }

    .my-card .list-group .list-group-item .div-days {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .my-card .list-group .list-group-item .div-days i {
        color: #0d6efd;
        font-size: 2.5rem;
    }

    .my-card .list-group .list-group-item .div-days div {
        display: flex;
        flex-direction: column;
    }

    .my-card .list-group .list-group-item .div-days div .number {
        font-size: 1rem;
        font-weight: bold;
        color: #252f4a;
    }

    .my-card .list-group .list-group-item .div-days div .day {
        font-size: 0.8rem;
        font-weight: normal;
        color: #000;
    }

    .my-card .list-group .list-group-item .div-value-average {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .my-card .list-group .list-group-item .div-value-average span {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        color: #252f4a;
        font-size: 1.8rem;
    }

    .my-card .list-group .list-group-item .div-value-average span span {
        font-size: 0.85rem;
        font-weight: normal;
        margin-right: 10px;
    }

    .my-card .list-group .li-card-calculated-average div {
        display: flex;
        flex-direction: column;
    }

    .my-card .list-group .li-card-calculated-average div {
        width: 100%;
    }

    .my-card
        .list-group
        .li-card-calculated-average
        div
        .div-card-calculated-average {
        display: flex;
        flex-direction: row;
    }

    .my-card .list-group .li-card-calculated-average div .card-body-averages {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .my-card
        .list-group
        .li-card-calculated-average
        div
        .card-body-averages
        div
        i {
        font-size: 2rem;
    }

    .my-card
        .list-group
        .li-card-calculated-average
        div
        .card-body-averages
        div
        h5 {
        margin: 0;
    }

    .average-calculate {
        font-size: 1.8rem;
        font-weight: bold;
    }

    .div-chart-icon i {
        font-size: 3rem;
        margin-bottom: 10px;
    }

    .my-card
        .list-group
        .li-card-calculated-average
        div
        .div-card-calculated-average
        .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    @media screen and (min-width: 800px) {
        .cards {
            flex-direction: row;
        }

        .my-card {
            width: 500px;
        }
    }
`;
