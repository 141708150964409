import styled from "styled-components";

export const ModalLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 186px;
    background-color: #ffffffb0;
    transition: all 0.4s;

    .spinner-grow {
        width: 2rem;
        height: 2rem;
    }
`;
