import React from "react";
import ModalLoading from "../ModalLoading";
import * as S from "./styles";

const Orders = (props) => {
    return (
        <S.Orders>
            {props.LoadingControlModal === true && <ModalLoading />}

            {props.LoadingControlModal !== true &&
            props.OrdersPredicted.length > 0 ? (
                <div className="table-responsive div-main-content">
                    <h5 className="mb-4">Ordens previstas</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">OM</th>
                                <th scope="col">Lote</th>
                                <th scope="col">Lançamento</th>
                                <th scope="col">Início</th>
                                <th scope="col">Produzir</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.OrdersPredicted.map((omPrev) => (
                                <tr key={omPrev.om}>
                                    <td>{omPrev.om}</td>
                                    <td>{omPrev.lote}</td>
                                    <td>
                                        {omPrev.dtlanc
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                    </td>
                                    <td>
                                        {omPrev.dtprevinicio
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                    </td>
                                    <td>{omPrev.qtproduzir}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <button
                        className="btn btn-success mt-2"
                        onClick={() =>
                            props.downloadExcel(
                                props.OrdersPredicted,
                                "ordens-prev"
                            )
                        }
                    >
                        Excel <i className="bi bi-download"></i>
                    </button>
                </div>
            ) : (
                props.LoadingControlModal !== true && (
                    <div className="div-main-content">
                        <h5 className="mb-4">Ordens previstas</h5>
                        <div className="alert alert-warning mt-4" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                            &nbsp; Este produto não possui ordens previstas.
                        </div>
                    </div>
                )
            )}

            {props.LoadingControlModal !== true &&
            props.OrdersProduction.length > 0 ? (
                <div className="table-responsive div-main-content">
                    <h5 className="mb-4">Ordens em andamento</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">OM</th>
                                <th scope="col">Lote</th>
                                <th scope="col">Lançamento</th>
                                <th scope="col">Início</th>
                                <th scope="col">Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.OrdersProduction.map((omProd) => (
                                <tr key={omProd.om}>
                                    <td>{omProd.om}</td>
                                    <td>{omProd.lote}</td>
                                    <td>
                                        {omProd.dtlanc
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                    </td>
                                    <td>
                                        {omProd.dtinicio
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                    </td>
                                    <td>{omProd.qt}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <button
                        className="btn btn-success mt-2"
                        onClick={() =>
                            props.downloadExcel(
                                props.OrdersProduction,
                                "ordens-prod"
                            )
                        }
                    >
                        Excel <i className="bi bi-download"></i>
                    </button>
                </div>
            ) : (
                props.LoadingControlModal !== true && (
                    <div className="div-main-content">
                        <h5 className="mb-4">Ordens em andamento</h5>
                        <div className="alert alert-warning mt-4" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                            &nbsp; Este produto não possui ordens em andamento.
                        </div>
                    </div>
                )
            )}
        </S.Orders>
    );
};

export default Orders;
