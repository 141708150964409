import React from "react";
import ModalLoading from "../ModalLoading";
import * as S from "./styles";

const Lots = (props) => {
    return (
        <S.Lots>
            {props.LoadingControlModal === true && <ModalLoading />}

            {props.LoadingControlModal !== true && props.Lots.length > 0 ? (
                <div className="table-responsive div-lots div-main-content">
                    <h5 className="mb-4">Informações dos lotes</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Lote</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col">Bloqueado</th>
                                <th scope="col">Manipulado</th>
                                <th scope="col">Validade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.Lots.map((lot) => (
                                <tr
                                    key={lot.num_lote}
                                    className={
                                        lot.qt === lot.qtbloqueada
                                            ? "table-danger"
                                            : lot.qtbloqueada > 0 &&
                                              lot.qtbloqueada < lot.qt
                                            ? "table-warning"
                                            : ""
                                    }
                                >
                                    <td>{lot.num_lote}</td>
                                    <td>{lot.qt}</td>
                                    <td>{lot.qtbloqueada}</td>
                                    <td>
                                        {lot.dtmanip
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                    </td>
                                    <td>
                                        {lot.dtvalid
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="div-lots-options mt-4">
                        <button
                            className="btn btn-success"
                            onClick={() =>
                                props.downloadExcel(props.Lots, "lotes")
                            }
                        >
                            Excel <i className="bi bi-download"></i>
                        </button>
                        <div className="div-legends">
                            <div className="legend-blocked ms-3">
                                <i className="bi bi-circle-fill"></i> Bloqueado
                            </div>
                            <div className="legend-partial-bloked ms-3">
                                <i className="bi bi-circle-fill"></i>{" "}
                                Parcialmente bloqueado
                            </div>
                            <div className="legend-released ms-3">
                                <i className="bi bi-circle"></i> Liberado
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                props.LoadingControlModal !== true && (
                    <div className="table-responsive div-lots div-main-content">
                        <h5 className="mb-4">Informações dos lotes</h5>
                        <div className="alert alert-warning mt-4" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                            &nbsp; Este produto não possui lote.
                        </div>
                    </div>
                )
            )}
        </S.Lots>
    );
};

export default Lots;
