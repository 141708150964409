import React from "react";
import * as S from "./styles";

const Ops = (props) => {
    //Trantando as OPs ugentes
    let cardStatusUrgente = "card-btn-op";
    let cardTextStatusUrgente = "text-primary";

    if (Number(props.urgente) === 1) {
        cardStatusUrgente = "card-btn-op-urgente";
        cardTextStatusUrgente = "text-danger";
    }

    //Convertendo a data
    const date = new Date(props.data);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const yaer = date.getFullYear();

    return (
        <S.Ops className="col-sm-6 col-md-4 col-lg-3">
            <div className="accordion mb-3" id={"accordion" + props.om}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={
                                "accordion-button text-white collapsed " +
                                cardStatusUrgente
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + props.om}
                            aria-expanded="false"
                            aria-controls="collapseOne"
                        >
                            <h4 className="mb-0 text-white">
                                OM - {props.om} <br />
                                <p className="mb-0 mt-1 card-btn-data">
                                    {`${day}/${month}/${yaer}`}
                                </p>
                            </h4>
                        </button>
                    </h2>
                    <div
                        id={"collapse" + props.om}
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent={"#accordion" + props.om}
                    >
                        <div className="accordion-body pe-0 ps-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item text-secondary">
                                    <label className={cardTextStatusUrgente}>
                                        Pedido
                                    </label>{" "}
                                    - {props.pedido}
                                    <label
                                        className={
                                            "ms-3 " + cardTextStatusUrgente
                                        }
                                    >
                                        QT
                                    </label>
                                    - {props.qtd}
                                </li>
                                <li className="list-group-item text-secondary">
                                    <label className={cardTextStatusUrgente}>
                                        Cliente
                                    </label>
                                    <br /> {props.cliente}
                                    <br />
                                    <label className={cardTextStatusUrgente}>
                                        Paciente
                                    </label>
                                    <br /> {props.paciente}
                                </li>
                                <li className="list-group-item text-secondary">
                                    <label className={cardTextStatusUrgente}>
                                        Médico
                                    </label>
                                    <br /> {props.medico} <br />
                                    <label className={cardTextStatusUrgente}>
                                        CRM
                                    </label>
                                    - {props.crm}
                                </li>
                                <li className="list-group-item text-secondary">
                                    <label className={cardTextStatusUrgente}>
                                        Princípio Ativo
                                    </label>
                                    <br /> {props.principioAtivo}
                                    <br />
                                    <label className={cardTextStatusUrgente}>
                                        Embalagem
                                    </label>
                                    - {props.embalagem}
                                </li>
                            </ul>
                            <div className="me-3 ms-3 mt-3 d-grid gap-2">
                                <a href="/#" className="btn btn-success btn-lg">
                                    Finalizar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </S.Ops>
    );
};

export default Ops;
