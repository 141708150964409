import React from "react";
import * as S from "./styles";

const TablePlaceholder = () => {
    const arrayTd = [];
    for (let i = 0; i < 50; i++) {
        arrayTd.push(i);
    }

    return (
        <S.TablePlaceholder>
            <div className="div-placeholder table-responsive">
                <div className="div-placeholder-option-column placeholder-glow">
                    <div className="div-placeholder-left">
                        <button
                            className="btn btn-secondary btn-sm btn-search disabled placeholder me-2"
                            aria-disabled="true"
                        ></button>
                        <button
                            className="btn btn-secondary btn-sm select-number-page disabled placeholder"
                            aria-disabled="true"
                        ></button>
                    </div>

                    <div className="div-placeholder-right">
                        <button
                            className="btn btn-secondary btn-sm btn-excel disabled placeholder me-2"
                            aria-disabled="true"
                        ></button>

                        <div>
                            <button
                                className="btn btn-secondary btn-sm btn-number-pagination disabled placeholder me-2"
                                aria-disabled="true"
                            ></button>

                            <button
                                className="btn btn-secondary btn-sm btn-pagination disabled placeholder"
                                aria-disabled="true"
                            ></button>
                        </div>
                    </div>
                </div>

                <table className="table table-placeholder">
                    <thead>
                        <tr>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                            <th className="placeholder-glow">
                                <span className="placeholder bg-secondary placeholder-lg"></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {arrayTd.map((index) => {
                            return (
                                <tr key={index}>
                                    <td className="placeholder-glow">
                                        <span className="placeholder cod-product-placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder name-product-placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                    <td className="placeholder-glow">
                                        <span className="placeholder placeholder-xs bg-secondary"></span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </S.TablePlaceholder>
    );
};

export default TablePlaceholder;
