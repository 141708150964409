import styled from "styled-components";

export const Stock = styled.div`
    .div-main-status-products .card {
        margin-bottom: 28px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
        border: none;
        transition: 0.3s;
    }

    .div-main-status-products .card:hover {
        box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.6);
    }

    .div-main-status-products .card p {
        padding: 10px 20px;
    }

    .div-main-status-products .card .card-icon {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .div-main-status-products .card .card-icon i {
        color: #00b3e4;
        font-size: 2.5rem;
    }

    .div-main-status-products .card .card-info {
        font-size: 3.75rem;
        font-weight: bold;
        color: #252f4a;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .div-main-status-products .card .card-title {
        font-size: 1rem;
        font-weight: 650;
        color: #252f4a;
        margin: 0;
        margin-top: -8px;
        padding-top: 0;
        padding-bottom: 25px;
    }

    @media screen and (min-width: 500px) {
        .div-main-status-products {
            display: grid;
            column-gap: 25px;
            row-gap: 25px;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            margin-bottom: 40px;
        }
    }

    @media screen and (min-width: 768px) {
        .div-main-status-products .card .dropdown .dropdown-menu-bloqueado {
            width: auto;
        }
    }
`;
