import styled from "styled-components";

export const PcmTable = styled.div`
    .bg-color-product {
        background-color: #dae9f8 !important;
    }

    .bg-color-product-light {
        background-color: #dae9f8b5;
    }

    .bg-color-lots {
        background-color: #c0e6f5 !important;
    }

    .bg-color-lots-light {
        background-color: #c0e6f5b8;
    }

    .bg-color-expedition {
        background-color: #daf2d0 !important;
    }

    .bg-color-expedition-light {
        background-color: #daf2d0b0;
    }

    .bg-color-balances {
        background-color: #f9d1c9 !important;
    }

    .bg-color-balances-light {
        background-color: #f9d1c9a3;
    }

    .bg-color-calculations {
        background-color: #dae9f8 !important;
    }

    .bg-color-calculations-light {
        background-color: #dae9f8b5;
    }

    .bg-color-details {
        background-color: #fff !important;
    }

    .bg-color-details-light {
        background-color: #ffffffa1;
    }

    .div-table {
        border: 1px solid #ddd;
        height: 100vh;
        overflow-y: auto;
        padding-top: 214px;
        padding-bottom: 0;
    }

    .table-pcm {
        width: 100%;
        margin-left: -1px;
        margin-bottom: 0;
        font-size: 0.8rem;
        border-collapse: separate;
        border-spacing: 0;
    }

    .table-pcm > :not(caption) > * > * {
        // border-bottom-width: 0;
        // border-right-width: 1px;
        border-bottom: 1px solid #aaa;
        border-right: 1px solid #aaa;
    }

    .table-pcm thead {
        width: 100%;
        background-color: #f5f5f5;
    }

    .table-pcm thead .tr-columns-group th {
        position: sticky;
        top: 0px;
        background-color: rgb(240, 245, 250);
        padding: 14px 16px;
        border-top: 1px solid #aaa;
        border-bottom: 1px solid #aaa;
    }

    .table-pcm thead .tr-columns th {
        position: sticky;
        top: 49px;
        background-color: rgb(240, 245, 250);
        padding: 14px 16px;
        // border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #000;
    }

    .table-pcm thead .tr-columns th .thead-div-arrow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .table-pcm thead .tr-columns th .thead-div-arrow i {
        font-size: 0.6rem;
    }

    .table-pcm thead .tr-columns th .div-thead-arrow-up-down {
        display: flex;
        flex-direction: column;
    }

    .table-pcm thead .tr-columns th .div-thead-arrow-up-down i {
        margin-bottom: -7px;
    }

    .table-pcm tbody > tr:nth-child(2n) {
        background-color: #f9f9f9;
    }

    .table-pcm tbody > tr:hover {
        background-color: #00b4e552;
    }

    .table-pcm tbody tr td .cell-name {
        display: block;
        min-width: 300px;
    }

    .selected-row {
        background-color: #00b4e596 !important;
    }

    .table-pcm tbody tr td {
        padding-left: 16px;
    }

    .table-pcm tbody tr td .div-btn-detail {
        text-align: center;
    }

    .table-pcm tbody tr td .div-btn-detail button {
        font-size: 0.7rem;
        background-color: #0887c2;
        color: #fff;
    }

    .table-pcm tbody tr td .div-btn-detail button:hover {
        background-color: #096d9b;
    }

    @media (width > 900px) {
        .div-table {
            padding-top: 166px;
        }
    }
`;
