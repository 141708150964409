import styled from "styled-components";

export const Ops = styled.div`
    .card-btn-op {
        background-color: #17c0eb;
    }

    .card-btn-op-urgente {
        background-color: #e74c3c;
    }

    .card-btn-data {
        font-size: 0.875rem;
    }
`;
