import React from "react";

export const ColumnFilter = (props) => {
    const { filterValue, setFilter } = props.column;

    return (
        <input
            className="form-control form-control-sm mt-2 mb-2"
            value={filterValue || ""}
            onChange={(e) => setFilter(e.target.value)}
        />
    );
};
