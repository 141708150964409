import React from "react";
import * as S from "./styles";

const ModalHeader = (props) => {
    return (
        <S.ModalHeader>
            <div className="div-main-info-product">
                <div className="div-info-product">
                    <div className="nome">
                        <p>
                            {props.codeProduct} {" - "} {props.nameProduct}
                            &nbsp;
                            {props.und}
                        </p>
                    </div>

                    <div className="validity">
                        <p>VALIDADE / {props.validityProduct} DIAS</p>
                    </div>
                </div>
            </div>
        </S.ModalHeader>
    );
};

export default ModalHeader;
