import moment from "moment";
import { ColumnFilter } from "./columnFilter";

export const COLUMNS = [
    {
        Header: "OM",
        accessor: "om",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "Pedido",
        accessor: "pedido",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "QT",
        accessor: "qtd",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "Cliente",
        accessor: "cliente",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "Paciente",
        accessor: "paciente",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "Médico",
        accessor: "medico",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "CRM",
        accessor: "crm",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "Princípio_Ativo",
        accessor: "principioAtivo",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "Embalagem",
        accessor: "embalagem",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "Prioridade",
        accessor: "urgente",
        Cell: (props) => {
            let status = "";
            if (Number(props.value) === 0) {
                status = "Normal";
            } else {
                status = "Urgente";
            }
            return <span>{status}</span>;
        },
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: "Data",
        accessor: "data",
        Cell: (props) => {
            const formatedDate = moment(props.value).format("DD/MM/yyyy");
            return <span>{formatedDate}</span>;
        },
        Filter: ColumnFilter,
        disableFilters: true,
    },
];
