import React from "react";
import * as S from "./styles";

const OptionsTable = (props) => {
    return (
        <S.OptionsTable className="mb-4">
            <div className="div-opcoes-tabela-esquerda">
                <select
                    className="form-select select-qt-linhas me-3"
                    value={props.pageSize}
                    onChange={(e) => props.setPageSize(Number(e.target.value))}
                >
                    {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize} Linhas
                        </option>
                    ))}
                </select>

                <button
                    className="btn btn-success"
                    onClick={() => props.downloadExcel()}
                >
                    Excel <i className="bi bi-download"></i>
                </button>
            </div>

            <div className="div-opcoes-tabela-direita">
                <span className="me-3">
                    Página {props.pageIndex + 1} de {props.pageOptions.length}
                </span>
                <nav aria-label="...">
                    <ul className="pagination m-0">
                        <li
                            className={
                                !props.canPreviousPage
                                    ? "page-item disabled"
                                    : "page-item"
                            }
                            onClick={() => props.gotoPage(0)}
                        >
                            <button className="page-link">
                                <i className="bi bi-chevron-bar-left"></i>
                            </button>
                        </li>
                        <li
                            className={
                                !props.canPreviousPage
                                    ? "page-item disabled"
                                    : "page-item"
                            }
                            onClick={() => props.previousPage()}
                        >
                            <button className="page-link">
                                <i className="bi bi-chevron-left"></i>
                            </button>
                        </li>
                        <li
                            className={
                                !props.canNextPage
                                    ? "page-item disabled"
                                    : "page-item"
                            }
                            onClick={() => props.nextPage()}
                        >
                            <button className="page-link">
                                <i className="bi bi-chevron-right"></i>
                            </button>
                        </li>

                        <li
                            className={
                                !props.canNextPage
                                    ? "page-item disabled"
                                    : "page-item"
                            }
                            onClick={() => props.gotoPage(props.pageCount - 1)}
                        >
                            <button className="page-link">
                                <i className="bi bi-chevron-bar-right"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </S.OptionsTable>
    );
};

export default OptionsTable;
