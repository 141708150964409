import React, { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { Link } from "react-router-dom";
import * as S from "./styles";
import LogoEyephara from "../../assets/logo-pote.png";

const Headder = () => {
    const { logout, User } = useContext(AuthContext);

    //Pegando apenas o primeiro nome
    //const firstName = User.name.split(" ");

    const handleLogout = () => {
        logout();
    };

    return (
        <S.Headder className="fixed-top">
            <nav
                className="navbar navbar-expand-lg"
                style={{ backgroundColor: "#fff" }}
            >
                <div className="container-fluid">
                    <a className="navbar-brand" href="/#">
                        <img
                            className="logo-short"
                            src={LogoEyephara}
                            alt="Imagem do logo Eye Pharma."
                        />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link
                                    className="nav-link active"
                                    aria-current="page"
                                    to="/"
                                >
                                    Home
                                </Link>
                            </li>

                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Módulos
                                </a>
                                <ul className="dropdown-menu dropdown-custon">
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to="/pcm"
                                        >
                                            PCM
                                        </Link>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to="/manip"
                                        >
                                            Manip
                                        </Link>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to="/areaLog"
                                        >
                                            Área Log
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <div className="d-flex align-items-center mb-2 mb-lg-0">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="/#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="bi bi-person-circle logo-name-user me-2"></i>

                                        <span className="me-1">
                                            {User.name}
                                        </span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-custon">
                                        <li>
                                            <button
                                                className="dropdown-item"
                                                onClick={handleLogout}
                                            >
                                                Sair
                                            </button>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </S.Headder>
    );
};

export default Headder;
