import React from "react";
import ModalLoading from "../ModalLoading";
import * as tooltip from "react-tooltip";
import * as S from "./styles";

const Stock = (props) => {
    return (
        <S.Stock>
            <div
                className="tab-pane fade show active"
                id={"nav-stock" + props.InfoProducts.codigo}
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                tabIndex="0"
            >
                {props.LoadingControlModal === true && <ModalLoading />}

                {(props.LoadingControlModal !== true &&
                    props.InfoProducts.length !== 0) ||
                (props.LoadingControlModal !== true && props.InfoProducts) ? (
                    <div className="div-main-status-products div-main-content">
                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-place="top"
                                data-tooltip-content="Disponível + Bloqueado"
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-database me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qt
                                    ? props.InfoProducts.qt
                                    : props.InfoProducts.qt}
                            </p>
                            <p className="card-title">QT TOTAL</p>
                        </div>

                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-content="Lotes bloqueados."
                                data-tooltip-place="top"
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-lock-fill me-2"></i>
                            </p>

                            <p className="card-info">
                                {props.InfoProducts.bloq
                                    ? props.InfoProducts.bloq
                                    : props.InfoProducts.bloq}
                            </p>
                            <p className="card-title">
                                BLOQUEADO - MANIPULAÇÃO
                            </p>
                        </div>

                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-content="Ordens programadas."
                                data-tooltip-place="top"
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-eye-fill me-2"></i>
                            </p>

                            <p className="card-info">
                                {props.InfoProducts.qtprevista
                                    ? props.InfoProducts.qtprevista
                                    : props.InfoProducts.qtprevista}
                            </p>
                            <p className="card-title">ORDENS PROGRAMADAS</p>
                        </div>

                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-content="Ordens em processo."
                                data-tooltip-place="top"
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-gear-fill me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qtproducao
                                    ? props.InfoProducts.qtproducao
                                    : props.InfoProducts.qtproducao}
                            </p>
                            <p className="card-title">ORDENS EM PRODUÇÃO</p>
                        </div>

                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-content="Geral - Bloqueado - Reservados"
                                data-tooltip-place="top"
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-check-circle-fill me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.disponivel
                                    ? props.InfoProducts.disponivel
                                    : props.InfoProducts.disponivel}
                            </p>
                            <p className="card-title">DISPONÍVEL</p>
                        </div>

                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-place="top"
                                data-tooltip-content="Pedidos pendentes."
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-clock-history me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qtpend
                                    ? props.InfoProducts.qtpend
                                    : props.InfoProducts.qtpend}
                            </p>
                            <p className="card-title">PEDIDOS PENDENTES</p>
                        </div>

                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-place="top"
                                data-tooltip-content="Pedidos pendentes com programação."
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-clock-history me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qtpendcomprog
                                    ? props.InfoProducts.qtpendcomprog
                                    : props.InfoProducts.qtpendcomprog}
                            </p>
                            <p className="card-title">
                                PEDIDOS PENDENTES COM PROGRAMAÇÃO
                            </p>
                        </div>

                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-place="top"
                                data-tooltip-content="Pedidos pendentes sem programação."
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-clock-history me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qtpendsemprog
                                    ? props.InfoProducts.qtpendsemprog
                                    : props.InfoProducts.qtpendsemprog}
                            </p>
                            <p className="card-title">
                                PEDIDOS PENDENTES SEM PROGRAMAÇÃO
                            </p>
                        </div>

                        <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-place="top"
                                data-tooltip-content="Pedidos reservados."
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-hourglass-split me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qtreserv
                                    ? props.InfoProducts.qtreserv
                                    : props.InfoProducts.qtreserv}
                            </p>
                            <p className="card-title">PEDIDOS RESERVADOS</p>
                        </div>

                        {/* <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-place="top"
                                data-tooltip-content="Pedidos bloqueados."
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-file-earmark-lock2-fill me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qtbloq
                                    ? props.InfoProducts.qtbloq
                                    : props.InfoProducts.qtbloq}
                            </p>
                            <p className="card-title">PEDIDO BLOQUEADO</p>
                        </div> */}

                        {/* <div className="card ">
                            <p
                                className="card-icon"
                                data-tooltip-id={props.InfoProducts.codigo}
                                data-tooltip-place="top"
                                data-tooltip-content="Pedidos Pendentes + Pedidos Reservados + Pedidos Bloqueados"
                                data-tooltip-variant="info"
                            >
                                <i className="bi bi-database-exclamation me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qtpendreserv
                                    ? props.InfoProducts.qtpendreserv
                                    : props.InfoProducts.qtpendreserv}
                            </p>
                            <p className="card-title">TOTAL DE PEDIDOS</p>
                        </div> */}

                        <div
                            className="card"
                            data-tooltip-id={props.InfoProducts.codigo}
                            data-tooltip-place="top"
                            data-tooltip-content="Disponivel + Bloqueados + Produção - PendReserv"
                            data-tooltip-variant="info"
                        >
                            <p className="card-icon color-green-2">
                                <i className="bi bi-database-fill-check me-2"></i>
                            </p>
                            <p className="card-info">
                                {props.InfoProducts.qtgeral
                                    ? props.InfoProducts.qtgeral
                                    : props.InfoProducts.qtgeral}
                            </p>
                            <p className="card-title">GERAL</p>
                        </div>
                    </div>
                ) : (
                    props.LoadingControlModal !== true && (
                        <div className="table-responsive div-main-content">
                            <h5 className="mb-4">Informações de estoque</h5>
                            <div
                                className="alert alert-warning mt-4"
                                role="alert"
                            >
                                <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                                &nbsp; Não foi possível encontrar as informações
                                de estoque.
                            </div>
                        </div>
                    )
                )}
            </div>

            <tooltip.Tooltip id={props.InfoProducts.codigo} />
        </S.Stock>
    );
};

export default Stock;
