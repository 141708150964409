import styled from "styled-components";
import background_login from "../../assets/bg-login.jpg";

export const LoginPage = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;

    .div-form {
        max-width: 400px;
        background-color: #fff;
    }

    .div-form form {
        padding: 40px 20px;
    }

    .div-form form label {
        color: #002e79;
        font-weight: bold;
    }

    .btb-sigin {
        background-color: #00b4e5;
        border: none;
    }

    .btb-sigin:hover {
        background-color: #009ec9;
    }

    .div-copyright .copyright {
        margin-bottom: 0px;
        margin-top: 0;
        font-size: 0.875rem;
        text-align: center;
    }

    .div-copyright .copyright {
        padding: 0 10px;
    }

    .div-form .btn-show-password {
        position: absolute;
        top: 20px;
        right: 10px;
        border: none;
        background-color: #fff;
        font-size: 1.3rem;
        color: #0066b3;
    }

    @media screen and (min-width: 1024px) {
        justify-content: right;
        background-image: url(${background_login});
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-attachment: fixed;

        .div-form {
            margin-top: 0;
            max-width: 550px;
            background-color: #fff;
        }

        .div-form form {
            padding: 40px 80px;
        }
    }
`;
