import React from "react";
import ModalLoading from "../ModalLoading";
import * as S from "./styles";

const Customization = (props) => {
    return (
        <S.Customization>
            {props.LoadingControlModal === true && <ModalLoading />}

            <div>
                <div className="div-main-content">
                    <h5>Parâmetros</h5>

                    <div className="row g-3 align-items-center mt-2">
                        <div className="col-auto">
                            <label
                                htmlFor={
                                    "inputAverageCustumization" +
                                    props.InfoProducts.codigo
                                }
                                className="col-form-label text-black"
                            >
                                Media atribuída
                            </label>
                        </div>
                        <div className="col-auto">
                            <input
                                type="text"
                                id={
                                    "inputAverageCustumization" +
                                    props.InfoProducts.codigo
                                }
                                className="form-control"
                                aria-describedby="passwordHelpInline"
                            />
                        </div>
                        <div className="col-auto">
                            <span
                                id="passwordHelpInline"
                                className="form-text text-body-tertiary"
                            >
                                Informe a quantidade de meses para que a média
                                seja gerada.
                            </span>
                        </div>
                    </div>

                    <div className="row g-3 align-items-center mt-2">
                        <div className="col-auto">
                            <label
                                htmlFor={
                                    "inputParameter2" +
                                    props.InfoProducts.codigo
                                }
                                className="col-form-label text-black"
                            >
                                Parâmetro 2
                            </label>
                        </div>
                        <div className="col-auto">
                            <input
                                type="text"
                                id={
                                    "inputParameter2" +
                                    props.InfoProducts.codigo
                                }
                                className="form-control"
                                aria-describedby="passwordHelpInline"
                            />
                        </div>
                        <div className="col-auto">
                            <span
                                id="passwordHelpInline"
                                className="form-text text-body-tertiary"
                            >
                                Outro parâmetro.
                            </span>
                        </div>
                    </div>

                    <div className="row g-3 align-items-center mt-2">
                        <div className="col-auto">
                            <label
                                htmlFor={
                                    "inputParameter3" +
                                    props.InfoProducts.codigo
                                }
                                className="col-form-label text-black"
                            >
                                Parâmetro 3
                            </label>
                        </div>
                        <div className="col-auto">
                            <input
                                type="text"
                                id={
                                    "inputParameter3" +
                                    props.InfoProducts.codigo
                                }
                                className="form-control"
                                aria-describedby="passwordHelpInline"
                            />
                        </div>
                        <div className="col-auto">
                            <span
                                id="passwordHelpInline"
                                className="form-text text-body-tertiary"
                            >
                                Outro parâmetro.
                            </span>
                        </div>
                    </div>

                    <div className="row g-3 align-items-center mt-2">
                        <div className="col-auto">
                            <label
                                htmlFor={
                                    "inputParameter4" +
                                    props.InfoProducts.codigo
                                }
                                className="col-form-label text-black"
                            >
                                Parâmetro 4
                            </label>
                        </div>
                        <div className="col-auto">
                            <input
                                type="text"
                                id={
                                    "inputParameter4" +
                                    props.InfoProducts.codigo
                                }
                                className="form-control"
                                aria-describedby="passwordHelpInline"
                            />
                        </div>
                        <div className="col-auto">
                            <span
                                id="passwordHelpInline"
                                className="form-text text-body-tertiary"
                            >
                                Outro parâmetro.
                            </span>
                        </div>
                    </div>

                    <button type="button" className="btn btn-primary mt-5">
                        Gravar
                    </button>
                </div>
            </div>
        </S.Customization>
    );
};

export default Customization;
