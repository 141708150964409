import axios from "axios";

export const apiBase = axios.create({
    baseURL: "https://arabio.com.br",
});

export const createSession = async (email, password) => {
    return apiBase
        .post("/login", { login: email, senha: password })
        .then((response) => response)
        .catch((error) => error);
};

export const destroySession = async () => {
    return apiBase
        .post("/logout", {})
        .then((response) => response)
        .catch((error) => error);
};

export const getInfoUser = async (idUser) => {
    return apiBase
        .get(`/usuario/${idUser}`)
        .then((response) => response)
        .catch((error) => error);
};

export const getOps = async (filial) => {
    return apiBase
        .get(`/manip/${filial}`)
        .then((response) => response)
        .catch((error) => error);
};

export const getOm = async (codProd, endPoint) => {
    return apiBase
        .get(`/om/${endPoint}/${codProd}`)
        .then((response) => response)
        .catch((error) => error);
};

export const getProducts = async () => {
    return apiBase
        .get(`/pcm/`)
        .then((response) => response)
        .catch((error) => error);
};

export const getLots = async (codProd) => {
    return apiBase
        .get(`/lotes/${codProd}`)
        .then((response) => response)
        .catch((error) => error);
};

export const getClosedSales = async (codProd) => {
    return apiBase
        .get(`/vendasf/${codProd}`)
        .then((response) => response)
        .catch((error) => error);
};

export const getProgrammedSales = async (codProd) => {
    return apiBase
        .get(`/vendasfp/${codProd}`)
        .then((response) => response)
        .catch((error) => error);
};

export const getProduct = async (codProd) => {
    return apiBase
        .get(`/pcm/prod/${codProd}`)
        .then((response) => response)
        .catch((error) => error);
};

export const checkTokenValidity = () => {
    // Recuperando o token do local storage
    const token = localStorage.getItem("token");
    // Decodifica o payload do token JWT
    const tokenData = JSON.parse(atob(token.split(".")[1]));
    // Verifica a data de expiração do token em relação ao tempo atual
    const tokenExperado = Date.now() >= tokenData.exp * 1000;

    //Se o retorno for True o token experirou, se o retorno for false o token ainda está valido.
    return tokenExperado;
};
