import styled from "styled-components";

export const SideBarAreaLog = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: 100%;
    padding: 90px 10px 30px 10px;
    //border-right: 1px solid #ed1c23;
    color: #00377f;
    background-color: #f0f5ff;
    transition: all 0.4s;

    nav {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    nav a {
        height: 60px;
        background-color: transparent;
        border: 0;
        font-family: inherit;
        color: inherit;
        text-align: left;
        padding: 0;
    }

    nav a > span {
        display: inline-flex;
        align-items: center;
        gap: 12px;
        height: 48px;
        padding: 0 16px;
        border-radius: 24px;
        line-height: 1;
        width: 100%;
        transition: all 0.2s;
    }

    nav a > span:hover {
        background: rgba(1, 59, 130, 0.1);
    }

    nav a i {
        position: relative;
        font-size: 28px;
        transition: 0.2s;
    }

    .active-option {
        //background: rgba(1, 59, 130, 1);
        background: rgba(1, 59, 130, 0.2);
        color: #00377f;
    }

    // .active-option:hover {
    //     background: rgba(1, 59, 130, 1);
    //     color: #fff;
    // }

    @media (width < 1368px) {
        width: 72px;

        nav a > span {
            width: 50px;
            padding: 0 12px;
        }

        nav a > span > span {
            opacity: 0;
            visibility: hidden;
        }
    }

    // @media (width < 1200px) {
    //     display: none;
    // }
`;
