import styled from "styled-components";

export const TablePlaceholder = styled.div`
    .div-placeholder {
        border: 1px solid #dee2e6;
        background-color: #f5f5f5;
        padding-top: 112px;
        padding-bottom: 0;
        height: 100vh;
        overflow-y: auto;
    }

    .div-placeholder-option-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 7px;
        border-bottom: 1px solid #dee2e6;
    }

    .div-placeholder-left {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .div-placeholder-left .btn-search {
        width: 100%;
    }

    .div-placeholder-left .select-number-page {
        width: 120px;
    }

    .div-placeholder-right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .div-placeholder-right .btn-excel {
        min-width: 70px;
    }

    .div-placeholder-right .btn-number-pagination {
        width: 70px;
    }

    .div-placeholder-right .btn-pagination {
        width: 165px;
    }

    .table-placeholder thead tr th span {
        min-width: 60px;
    }

    .table-placeholder tbody tr td {
        background-color: #fcfcfc;
    }

    .table-placeholder tbody tr td span {
        min-width: 30px;
        height: 20px;
    }

    .table-placeholder tbody tr td .name-product-placeholder {
        min-width: 300px;
    }

    .table-placeholder tbody tr td .cod-product-placeholder {
        min-width: 100px;
    }

    @media (width > 900px) {
        .div-placeholder-option-column {
            flex-direction: row;
        }

        .div-placeholder-left {
            margin-bottom: 0px;
        }

        .div-placeholder-left .btn-search {
            width: 287px;
        }

        .div-placeholder-right .btn-number-pagination {
            width: 95px;
        }
    }
`;
