import { ColumnFilter } from "./ColumnFilter";

function todayDate() {
    //Pegando a data de hoje
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const date = `${year}-${month}-${day}`;
    return date;
}

function calculateDifferenceInDays(date1, date2) {
    // Convertendo as datas para objetos Date, se elas não forem objetos Date
    if (!(date1 instanceof Date)) date1 = new Date(date1);
    if (!(date2 instanceof Date)) date2 = new Date(date2);

    // Calculando a diferença em milissegundos
    const differenceInMilliseconds = Math.abs(date2 - date1);

    // Convertemos a diferença em dias
    const differenceInDays = Math.floor(
        differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return differenceInDays;
}

export const COLUMNS = [
    //GRUPO - INFORMAÇÕES DO PRODUTO

    {
        header: "Código",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Código do produto."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Código
                </span>
            );
        },
        accessor: "codigo",
        Filter: ColumnFilter,
        disableFilters: true,
    },

    {
        header: "Nome",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Nome do produto."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Nome
                </span>
            );
        },
        accessor: "nome",
        Filter: ColumnFilter,
        disableFilters: true,
        Cell: (props) => {
            return <span className="cell-name">{props.value}</span>;
        },
    },

    {
        header: "UND",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Unidade de medida."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    UND
                </span>
            );
        },
        accessor: "und",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    {
        header: "Valid",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Validade em dias."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Valid
                </span>
            );
        },
        accessor: "validade",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            return <span>{props.value + " dias"}</span>;
        },
    },

    //GRUPO - iNFORMAÇÕES DO LOTES

    {
        header: "QT",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Disponível + Bloqueado"
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    QT
                </span>
            );
        },
        accessor: "qt",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    {
        header: "Bloq",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Lotes bloqueados."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Bloq
                </span>
            );
        },
        accessor: "bloq",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    {
        header: "Previsto",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Ordens programadas."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Previsto
                </span>
            );
        },
        accessor: "qtprevista",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    {
        header: "Produção",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Ordens em processo."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Produção
                </span>
            );
        },
        accessor: "qtproducao",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    {
        header: "Disp",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Geral - Bloqueado - Reservados"
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Disp
                </span>
            );
        },
        accessor: "disponivel",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    //GRUPO - EXPEDIÇÃO

    {
        header: "Pendente",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Pedidos pendentes."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Pendente
                </span>
            );
        },
        accessor: "qtpend",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        // Cell: (props) => {
        //     console.log(props.row.original.qtpendcomprog);
        //     console.log(props.row.original.qtpendsemprog);

        //     return (
        //         <div>
        //             <span>C P &nbsp;{"="}</span>&nbsp;
        //             {props.row.original.qtpendcomprog} <br />
        //             <span>S P &nbsp;{"="}</span>&nbsp;
        //             {props.row.original.qtpendsemprog}
        //         </div>
        //     );
        // },
    },

    {
        header: "Reserv",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Pedidos reservados."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Reserv
                </span>
            );
        },
        accessor: "qtreserv",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    // GRUPO - SALDOS

    {
        header: "Geral",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Disponivel + Bloqueados + Produção - PendReserv"
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Geral
                </span>
            );
        },
        accessor: "qtgeral",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    //GRUPO - CÁCULOS PCM

    {
        header: "M23",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Razão MVendas / 23 dias."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    M23
                </span>
            );
        },
        accessor: "m23",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    {
        header: "Estoque",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Quandidade Geral / M23."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    Estoque
                </span>
            );
        },
        accessor: "calc23",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
    },

    {
        header: "%Vendas",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="% entre M30Dias e M23."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    %Vendas
                </span>
            );
        },
        accessor: "percvenda",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: false,
        Cell: (props) => {
            return <span>{props.value + " %"}</span>;
        },
    },

    {
        header: "PrevEstoque",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Data prevista para o término do estoque de acordo com a data atual."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    EstoqueEmDias
                </span>
            );
        },
        accessor: "dataprevestoque",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: false,
        Cell: (props) => {
            // Pegando a data de hoje
            const dateToday = todayDate();

            // Pegando a data do array
            const date = props.value;

            //Calculando a diferença em dias das datas
            const date1 = new Date(dateToday);
            const date2 = new Date(date);
            const differenceInDays = calculateDifferenceInDays(date1, date2);

            //Verificando se data de reposição já ultrapassou
            let classText = "d-block text-center";

            return (
                <span className={classText}>
                    {differenceInDays} dias -{" "}
                    {props.value.slice(0, 10).split("-").reverse().join("/")}
                </span>
            );
        },
    },

    {
        header: "RepoEstoque",
        Header: () => {
            return (
                <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Data prevista para reposição do estoque."
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                >
                    PrevisãoDeReposição
                </span>
            );
        },
        accessor: "datarepo",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: false,
        Cell: (props) => {
            // Pegando a data de hoje
            const dateToday = todayDate();

            // Pegando a data do array
            const date = props.value;

            //Calculando a diferença em dias das datas
            const date1 = new Date(dateToday);
            const date2 = new Date(date);

            //Verificando se data de reposição já ultrapassou
            let classText = "d-block text-center";
            if (Math.abs(date2) < Math.abs(date1)) {
                classText = "text-body-tertiary d-block text-center";
            }

            //const differenceInDays = calculateDifferenceInDays(date1, date2);

            return (
                <span className={classText}>
                    {/* {differenceInDays} dias -{" "} */}
                    {props.value.slice(0, 10).split("-").reverse().join("/")}
                </span>
            );
        },
    },

    {
        header: "Detalhes",
        Header: "Detalhes",
        accessor: "detalhes",
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            return (
                <div className="div-btn-detail">
                    <button
                        data-bs-toggle="modal"
                        data-bs-target={"#detail" + props.row.id}
                        className="btn btn-sm"
                    >
                        <i className="bi bi-search"></i>
                    </button>
                </div>
            );
        },
    },
];
