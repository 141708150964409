import React from "react";
import ModalLoading from "../ModalLoading";
import * as S from "./styles";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Filler,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const Monthly = (props) => {
    let data = props;

    //###### Média móvel ##########
    const arrayMonth3 = [null, null, null, null, null, null, null, null, null];
    const arrayMonth6 = [null, null, null, null, null, null];
    const arrayMonth12 = [];

    const periodOfMonths3 = 3;
    const periodOfMonths6 = 6;
    const periodOfMonths12 = 12;

    function averageCalc(periodOfMonths, targetArray) {
        //targetArray.length = 0;

        const length = data.ClosedMonthSales.length;
        if (length < periodOfMonths) return;

        // Calcula a média móvel apenas para os últimos meses disponíveis
        for (let i = length - periodOfMonths; i < length; i++) {
            let sum = 0;
            for (let j = i - periodOfMonths + 1; j <= i; j++) {
                // Verifica se o índice está dentro dos limites do array
                if (
                    data.ClosedMonthSales[j] &&
                    data.ClosedMonthSales[j].qt !== undefined
                ) {
                    sum += data.ClosedMonthSales[j].qt;
                }
            }
            targetArray.push(sum / periodOfMonths);
        }
    }

    // Calcula as médias móveis
    averageCalc(periodOfMonths3, arrayMonth3);
    averageCalc(periodOfMonths6, arrayMonth6);
    averageCalc(periodOfMonths12, arrayMonth12);

    // Filtra os últimos 3, 6 e 12 meses
    const maxPeriod = Math.max(
        periodOfMonths3,
        periodOfMonths6,
        periodOfMonths12
    );
    const labels = data.ClosedMonthSales.slice(-maxPeriod).map((v) => v.mes);

    const dataBarSalesChart = {
        labels: labels,
        datasets: [
            {
                label: "Vendas",
                data: data.ClosedMonthSales.slice(-labels.length).map(
                    (v) => v.qt
                ),
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "Vendas programadas",
                data: data.ClosedMonthSalesScheduled.slice(-labels.length).map(
                    (v) => v.qt
                ),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                type: "line",
                label: "Média móvel de 3 meses",
                borderColor: "rgba(0, 148, 50,1.0)",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderWidth: 2,
                fill: false,
                spanGaps: true, // Configuração para não conectar gaps
                data: arrayMonth3,
                //borderDash: [5, 5], // Linhas pontilhadas
            },
            {
                type: "line",
                label: "Média móvel de 6 meses",
                borderColor: "rgba(255, 165, 2,1.0)",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderWidth: 2,
                fill: false,
                spanGaps: true, // Configuração para não conectar gaps
                data: arrayMonth6,
                //borderDash: [5, 5], // Linhas pontilhadas
            },
            {
                type: "line",
                label: "Média móvel de 12 meses",
                borderColor: "rgba(72, 52, 212,1.0)",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderWidth: 2,
                fill: false,
                spanGaps: true, // Configuração para não conectar gaps
                data: arrayMonth12,
                hidden: true, // Linha de média desabilitada inicialmente
                //borderDash: [5, 5], // Linhas pontilhadas
            },
        ],
    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        LineController,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );

    const barChartSalesOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "right",
            },
            title: {
                display: false,
                text: "Teste",
            },
        },
    };

    return (
        <S.Monthly>
            {data.LoadingControlModal === true && <ModalLoading />}

            {data.LoadingControlModal !== true &&
            data.ClosedMonthSales.length > 0 ? (
                <div className="table-responsive div-main-content">
                    <h5 className="mb-4">Vendas mês fechado / Média móvel</h5>

                    <div className="div-charts-product mb-5">
                        <Bar
                            options={barChartSalesOptions}
                            data={dataBarSalesChart}
                        />
                    </div>
                </div>
            ) : (
                data.LoadingControlModal !== true && (
                    <div className="table-responsive div-main-content">
                        <h5 className="mb-4">Vendas mês fechado</h5>
                        <div className="alert alert-warning mt-4" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                            &nbsp; Este produto não possui vendas mês fechado.
                        </div>
                    </div>
                )
            )}
        </S.Monthly>
    );
};

export default Monthly;
