import styled from "styled-components";

export const Lots = styled.div`
    .div-lots .div-lots-options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .div-lots .div-lots-options .btn-success {
        min-width: 90px;
    }

    .div-lots .div-lots-options .div-legends {
        display: flex;
        flex-direction: row;
        align-items: end;
        min-width: 420px;
    }

    .div-lots .div-lots-options .div-legends .legend-blocked i {
        color: #f8d7da;
    }

    .div-lots .div-lots-options .div-legends .legend-partial-bloked i {
        color: #fff3cd;
    }

    .div-lots .div-lots-options .div-legends .legend-released i {
        color: #333;
    }
`;
