import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../contexts/auth";
import {
    getLots,
    checkTokenValidity,
    apiBase,
    getOm,
    getClosedSales,
    getProgrammedSales,
    getProduct,
} from "../../../services/api";
import { useNavigate } from "react-router-dom";
import ModalHeadder from "./ModalHeadder";
import Stock from "./Stock";
import Avarages from "./Averages";
import Monthly from "./Monthly";
import LotComponent from "./Lots";
import Orders from "./Orders";
import Customization from "./Customization";
import * as S from "./styles";

const Modal = (props) => {
    const [LoadingControlModal, setLoadingControlModal] = useState(false);
    const [Lots, setLots] = useState([]);
    const [ClosedMonthSales, setClosedMonthSales] = useState([]);
    const [ClosedMonthSalesScheduled, setClosedMonthSalesScheduled] = useState(
        []
    );
    const [OrdersPredicted, setOrdersPredicted] = useState([]);
    const [OrdersProduction, setOrdersProduction] = useState([]);
    const [InfoProducts, setInfoProducts] = useState([]);
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    //Busca informações do produto quando a página é carregada pela primeira vez.
    useEffect(() => {
        (async () => {
            //Verificar a validade do Token
            const expiredToken = checkTokenValidity();

            if (expiredToken) {
                localStorage.removeItem("userName");
                localStorage.removeItem("token");
                apiBase.defaults.headers.Authorization = null;
                setUser(null);

                navigate("/login");
            } else {
                const response = await getProduct(props.row.original.codigo);

                if (response.status === 200) {
                    setInfoProducts(response.data.produto[0]);
                } else {
                    //Status do erro
                    console.log(response);
                }
            }
        })();
    }, []);

    //Busca informações do produto
    function getInfoProduct() {
        setLoadingControlModal(true);

        (async () => {
            //Verificar a validade do Token
            const expiredToken = checkTokenValidity();

            if (expiredToken) {
                localStorage.removeItem("userName");
                localStorage.removeItem("token");
                apiBase.defaults.headers.Authorization = null;
                setUser(null);

                navigate("/login");
            } else {
                const response = await getProduct(props.row.original.codigo);

                if (response.status === 200) {
                    setInfoProducts(response.data.produto[0]);
                    setLoadingControlModal(false);
                } else {
                    //Status do erro
                    console.log(response);
                }
            }
        })();
    }

    //Busca de lotes
    function getLotes() {
        setLoadingControlModal(true);

        (async () => {
            //Verificar a validade do Token
            const expiredToken = checkTokenValidity();

            if (expiredToken) {
                localStorage.removeItem("userName");
                localStorage.removeItem("token");
                apiBase.defaults.headers.Authorization = null;
                setUser(null);
                navigate("/login");
            } else {
                const response = await getLots(props.row.original.codigo);

                if (response.status === 200) {
                    setLots(response.data.lotes);
                    setLoadingControlModal(false);
                } else {
                    //Status do erro
                    console.log(response);
                }
            }
        })();
    }

    //Busca OM
    function getOrdens() {
        setLoadingControlModal(true);

        (async () => {
            //Verificar a validade do Token
            const expiredToken = checkTokenValidity();

            if (expiredToken) {
                localStorage.removeItem("userName");
                localStorage.removeItem("token");
                apiBase.defaults.headers.Authorization = null;
                setUser(null);
                navigate("/login");
            } else {
                const responsePredicted = await getOm(
                    props.row.original.codigo,
                    "previstas"
                );
                const responseProduction = await getOm(
                    props.row.original.codigo,
                    "prod"
                );

                if (
                    responsePredicted.status === 200 &&
                    responseProduction.status === 200
                ) {
                    setOrdersPredicted(responsePredicted.data.ordens);
                    setOrdersProduction(responseProduction.data.ordens);
                    setLoadingControlModal(false);
                } else {
                    //Status do erro
                    console.log(responsePredicted);
                    console.log(responseProduction);
                }
            }
        })();
    }

    //Busca de vendas mês fechado
    function getVendasMesFechado() {
        setLoadingControlModal(true);

        (async () => {
            //Verificar a validade do Token
            const expiredToken = checkTokenValidity();

            if (expiredToken) {
                localStorage.removeItem("userName");
                localStorage.removeItem("token");
                apiBase.defaults.headers.Authorization = null;
                setUser(null);
                navigate("/login");
            } else {
                const response = await getClosedSales(
                    props.row.original.codigo
                );
                const responseScheduled = await getProgrammedSales(
                    props.row.original.codigo
                );

                if (response.status === 200) {
                    setClosedMonthSales(response.data.vendas);
                    setClosedMonthSalesScheduled(responseScheduled.data.vendas);
                    setLoadingControlModal(false);
                } else {
                    //Status do erro
                    console.log(response);
                }
            }
        })();
    }

    return (
        <S.Modal
            className="modal fade modal-td p-0"
            id={"detail" + props.row.id}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            key={props.row.id}
        >
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            DETALHES DO PRODUTO
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <ModalHeadder
                            codeProduct={props.row.original.codigo}
                            nameProduct={props.row.original.nome}
                            und={props.row.original.und}
                            validityProduct={props.row.original.validade}
                        />
                        <div className="container-fluid">
                            {/*Nomes das abas*/}
                            <nav>
                                <div
                                    className="nav nav-tabs nav-modal-details"
                                    id="nav-tab"
                                    role="tablist"
                                >
                                    <button
                                        className="nav-link active"
                                        id="nav-stock-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target={
                                            "#nav-stock" +
                                            props.row.original.codigo
                                        }
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-stock"
                                        aria-selected="true"
                                        onClick={getInfoProduct}
                                    >
                                        ESTOQUE
                                    </button>

                                    <button
                                        className="nav-link"
                                        id="nav-averages-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target={
                                            "#nav-averages" +
                                            props.row.original.codigo
                                        }
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-averages"
                                        aria-selected="false"
                                        onClick={getInfoProduct}
                                    >
                                        MÉDIAS
                                    </button>

                                    <button
                                        className="nav-link"
                                        id="nav-monthly-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target={
                                            "#nav-monthly" +
                                            props.row.original.codigo
                                        }
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-monthly"
                                        aria-selected="false"
                                        onClick={getVendasMesFechado}
                                    >
                                        MENSAIS
                                    </button>

                                    <button
                                        className="nav-link"
                                        id="nav-lots-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target={
                                            "#nav-lots" +
                                            props.row.original.codigo
                                        }
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-lots"
                                        aria-selected="false"
                                        onClick={getLotes}
                                    >
                                        LOTES
                                    </button>

                                    <button
                                        className="nav-link"
                                        id="nav-orders-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target={
                                            "#nav-orders" +
                                            props.row.original.codigo
                                        }
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-orders"
                                        aria-selected="false"
                                        onClick={getOrdens}
                                    >
                                        ORDENS
                                    </button>

                                    <button
                                        className="nav-link"
                                        id="nav-customization-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target={
                                            "#nav-customization" +
                                            props.row.original.codigo
                                        }
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-customization"
                                        aria-selected="false"
                                    >
                                        CUSTOMIZAÇÃO
                                    </button>
                                </div>
                            </nav>

                            <div className="tab-content" id="nav-tabContent">
                                {/*Aba Estoque*/}
                                <div
                                    className="tab-pane fade show active"
                                    id={"nav-stock" + props.row.original.codigo}
                                    role="tabpanel"
                                    aria-labelledby="nav-home-tab"
                                    tabIndex="0"
                                >
                                    <Stock
                                        InfoProducts={InfoProducts}
                                        LoadingControlModal={
                                            LoadingControlModal
                                        }
                                    />
                                </div>

                                {/*Aba Médias*/}
                                <div
                                    className="tab-pane fade"
                                    id={
                                        "nav-averages" +
                                        props.row.original.codigo
                                    }
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                    tabIndex="0"
                                >
                                    <Avarages
                                        InfoProducts={InfoProducts}
                                        LoadingControlModal={
                                            LoadingControlModal
                                        }
                                    />
                                </div>

                                {/*Aba Mensais*/}
                                <div
                                    className="tab-pane fade"
                                    id={
                                        "nav-monthly" +
                                        props.row.original.codigo
                                    }
                                    role="tabpanel"
                                    aria-labelledby="nav-contact-tab"
                                    tabIndex="0"
                                >
                                    <Monthly
                                        LoadingControlModal={
                                            LoadingControlModal
                                        }
                                        ClosedMonthSales={ClosedMonthSales}
                                        ClosedMonthSalesScheduled={
                                            ClosedMonthSalesScheduled
                                        }
                                    />
                                </div>

                                {/*Aba Lotes*/}
                                <div
                                    className="tab-pane fade"
                                    id={"nav-lots" + props.row.original.codigo}
                                    role="tabpanel"
                                    aria-labelledby="nav-contact-tab"
                                    tabIndex="0"
                                >
                                    <LotComponent
                                        Lots={Lots}
                                        LoadingControlModal={
                                            LoadingControlModal
                                        }
                                    />
                                </div>

                                {/*Aba Ordens*/}
                                <div
                                    className="tab-pane fade"
                                    id={
                                        "nav-orders" + props.row.original.codigo
                                    }
                                    role="tabpanel"
                                    aria-labelledby="nav-contact-tab"
                                    tabIndex="0"
                                >
                                    <Orders
                                        LoadingControlModal={
                                            LoadingControlModal
                                        }
                                        OrdersPredicted={OrdersPredicted}
                                        OrdersProduction={OrdersProduction}
                                    />
                                </div>

                                {/*Aba Customização*/}
                                <div
                                    className="tab-pane fade"
                                    id={
                                        "nav-customization" +
                                        props.row.original.codigo
                                    }
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                    tabIndex="0"
                                >
                                    <Customization
                                        InfoProducts={InfoProducts}
                                        LoadingControlModal={
                                            LoadingControlModal
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            </div>
        </S.Modal>
    );
};

export default Modal;
