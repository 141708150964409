import React, { useMemo } from "react";
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    useFilters,
    usePagination,
} from "react-table";
import OptionsTable from "./OptionsTable";
import Checkbox from "./CheckBox";
import ModalTable from "./ModalTable";
import { COLUMNS } from "./columns";
import * as XLSX from "xlsx";
import * as S from "./styles";

const AreaLogTable = (props) => {
    const columns = useMemo(() => COLUMNS, []);
    const data = useMemo(() => props.dataLog, []);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        allColumns,
        getToggleHideAllColumnsProps,
    } = tableInstance;

    const { pageIndex, pageSize } = state;

    const downloadExcel = () => {
        const newData = data.map((row) => {
            delete row.tableData;
            return row;
        });
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "students");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(workBook, "OM.xlsx");
    };

    return (
        <S.AreaLogTable>
            {data !== "" && (
                <div>
                    {/* <OptionsTable
                        nextPage={nextPage}
                        previousPage={previousPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageOptions={pageOptions}
                        gotoPage={gotoPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        downloadExcel={downloadExcel}
                    /> */}

                    {/* <div className="div-show-columns mb-4">
                        <div className="form-check">
                            <Checkbox
                                className="form-check-input"
                                id="all-columns"
                                {...getToggleHideAllColumnsProps()}
                            />{" "}
                            <label
                                className="form-check-label"
                                htmlFor="all-columns"
                            >
                                Todas Colunas
                            </label>
                        </div>

                        {allColumns.map((column) => (
                            <div className="form-check" key={column.id}>
                                <input
                                    id={column.id}
                                    className="form-check-input"
                                    type="checkbox"
                                    {...column.getToggleHiddenProps()}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={column.id}
                                >
                                    {column.Header}
                                </label>
                            </div>
                        ))}
                    </div> */}

                    <button
                        type="button"
                        className="btn btn-secondary mb-3"
                        data-bs-toggle="modal"
                        data-bs-target={"#areaLogModal"}
                    >
                        <i className="bi bi-plus-lg"></i> Novo registro
                    </button>

                    <div className="table-responsive div-table-scroll">
                        <table
                            className="table table-hover"
                            {...getTableProps()}
                        >
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                            >
                                                <span className="thead-div-arrow">
                                                    <span>
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </span>

                                                    {!column.disableSortBy ? (
                                                        <span>
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <i className="bi bi-chevron-down ms-3" />
                                                                ) : (
                                                                    <i className="bi bi-chevron-up ms-3" />
                                                                )
                                                            ) : (
                                                                <span className="div-thead-arrow-up-down">
                                                                    <i className="bi bi-chevron-up ms-3" />
                                                                    <i className="bi bi-chevron-down ms-3" />
                                                                </span>
                                                            )}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>

                                                <span>
                                                    {column.canFilter
                                                        ? column.render(
                                                              "Filter"
                                                          )
                                                        : null}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            data-bs-toggle="modal"
                                            data-bs-target={"#" + row.id}
                                            {...row.getRowProps()}
                                        >
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}

                                            {/* <ModalTable row={row} /> */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        {<ModalTable />}
                    </div>

                    <OptionsTable
                        nextPage={nextPage}
                        previousPage={previousPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageOptions={pageOptions}
                        gotoPage={gotoPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        downloadExcel={downloadExcel}
                    />
                </div>
            )}
        </S.AreaLogTable>
    );
};

export default AreaLogTable;
