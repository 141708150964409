import React from "react";
import * as S from "./styles";

const Loading = () => {
    return (
        <S.Loading>
            {/*<div className="my-animation-loading"></div>*/}
            <div className="spinner-grow text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-info" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            {/* <div className="spinner-grow text-light" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> */}
            <div className="spinner-grow text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </S.Loading>
    );
};

export default Loading;
