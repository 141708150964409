import styled from "styled-components";

export const ManipPage = styled.div`
    .main-home {
        margin-top: 80px;
        padding: 0 15px;
    }

    // .titulo-home {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     color: #fff;
    //     background-color: #00b4e5;
    //     padding: 8px 15px;
    //     border-radius: 7px;
    //     border-bottom-left-radius: 0;
    //     border-bottom-right-radius: 0;
    //     font-size: 1.2rem;
    //     font-weight: bold;
    // }

    .hide-component {
        display: none;
    }

    .div-view-type {
        display: flex;
        flex-direction: row;
    }

    .div-view-type select {
        width: 180px;
    }

    .div-view-type button i {
        font-size: 1.2rem;
    }
`;
