import React from "react";
import ModalLoading from "../ModalLoading";
import * as S from "./styles";

const Avarages = (props) => {
    return (
        <S.Avarages>
            {props.LoadingControlModal === true && <ModalLoading />}

            {props.LoadingControlModal !== true &&
            props.InfoProducts.length !== 0 ? (
                <div className="div-main-content">
                    <div className="cards">
                        <div className="card my-card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item card-li-title">
                                    <span className="card-title">
                                        Média calculada
                                    </span>
                                    <span className="card-sub-title">
                                        Média de acompanhamento específico
                                    </span>
                                </li>

                                <li className="list-group-item li-card-calculated-average">
                                    <div>
                                        <div className="div-card-calculated-average">
                                            <div className="card text-bg-primary mb-3 me-3">
                                                <div className="card-body card-body-averages">
                                                    <div>
                                                        <i className="bi bi-calendar3"></i>
                                                    </div>

                                                    <div>
                                                        <h5 className="card-title">
                                                            {
                                                                props
                                                                    .InfoProducts
                                                                    .mcalc
                                                            }
                                                        </h5>
                                                        <p className="card-text">
                                                            Meses
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card text-bg-success mb-3">
                                                <div className="card-body card-body-averages">
                                                    <div>
                                                        <i className="bi bi-bag-check-fill"></i>
                                                    </div>
                                                    <div>
                                                        <h5 className="card-title">
                                                            {
                                                                props
                                                                    .InfoProducts
                                                                    .mvenda
                                                            }
                                                        </h5>
                                                        <p className="card-text">
                                                            Vendas
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div>
                                                <div className="card text-bg-light mb-3">
                                                    <div className="card-body">
                                                        <div className="div-chart-icon">
                                                            <i className="bi bi-graph-up-arrow"></i>
                                                        </div>
                                                        <div>
                                                            <h5 className="card-title average-calculate">
                                                                {
                                                                    props
                                                                        .InfoProducts
                                                                        .mvendamedia
                                                                }
                                                            </h5>
                                                            <p className="card-text">
                                                                Média calculada
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="card my-card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item card-li-title">
                                    <span className="card-title">
                                        Médias diárias
                                    </span>
                                    <span className="card-sub-title">
                                        Média diária vendida por periódo
                                    </span>
                                </li>
                                <li className="list-group-item">
                                    <div className="div-days">
                                        <i className="bi bi-calendar3 me-2"></i>{" "}
                                        <div>
                                            <span className="mt-1 number">
                                                30
                                            </span>{" "}
                                            <span className="day"> Dias</span>
                                        </div>
                                    </div>
                                    <div className="div-value-average">
                                        <span>
                                            <i className="bi bi-arrow-right-short me-1"></i>{" "}
                                            <span>Média de</span>{" "}
                                            {props.InfoProducts.m30d}
                                        </span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="div-days">
                                        <i className="bi bi-calendar3 me-2"></i>{" "}
                                        <div>
                                            <span className="mt-1 number">
                                                90
                                            </span>{" "}
                                            <span className="day"> Dias</span>
                                        </div>
                                    </div>
                                    <div className="div-value-average">
                                        <span>
                                            <i className="bi bi-arrow-right-short me-1"></i>{" "}
                                            <span>Média de</span>{" "}
                                            {props.InfoProducts.m90d}
                                        </span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="div-days">
                                        <i className="bi bi-calendar3 me-2"></i>{" "}
                                        <div>
                                            <span className="mt-1 number">
                                                180
                                            </span>{" "}
                                            <span className="day"> Dias</span>
                                        </div>
                                    </div>
                                    <div className="div-value-average">
                                        <span>
                                            <i className="bi bi-arrow-right-short me-1"></i>{" "}
                                            <span>Média de</span>{" "}
                                            {props.InfoProducts.m180d}
                                        </span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="div-days">
                                        <i className="bi bi-calendar3 me-2"></i>{" "}
                                        <div>
                                            <span className="mt-1 number">
                                                365
                                            </span>{" "}
                                            <span className="day"> Dias</span>
                                        </div>
                                    </div>
                                    <div className="div-value-average">
                                        <span>
                                            <i className="bi bi-arrow-right-short me-1"></i>{" "}
                                            <span>Média de</span>{" "}
                                            {props.InfoProducts.m365d}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="card my-card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item card-li-title">
                                    <span className="card-title">
                                        Totais de vendas
                                    </span>
                                    <span className="card-sub-title">
                                        Quantidades vendidas por periódo
                                    </span>
                                </li>

                                <li className="list-group-item">
                                    <div className="div-days">
                                        <i className="bi bi-calendar3 me-2"></i>{" "}
                                        <div>
                                            <span className="mt-1 number">
                                                30
                                            </span>{" "}
                                            <span className="day"> Dias</span>
                                        </div>
                                    </div>
                                    <div className="div-value-average">
                                        <span>
                                            <i className="bi bi-arrow-right-short me-1"></i>{" "}
                                            <span>Total de</span>{" "}
                                            {props.InfoProducts.qt30d}{" "}
                                        </span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="div-days">
                                        <i className="bi bi-calendar3 me-2"></i>{" "}
                                        <div>
                                            <span className="mt-1 number">
                                                90
                                            </span>{" "}
                                            <span className="day"> Dias</span>
                                        </div>
                                    </div>
                                    <div className="div-value-average">
                                        <span>
                                            <i className="bi bi-arrow-right-short me-1"></i>{" "}
                                            <span>Total de</span>{" "}
                                            {props.InfoProducts.qt90d}
                                        </span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="div-days">
                                        <i className="bi bi-calendar3 me-2"></i>{" "}
                                        <div>
                                            <span className="mt-1 number">
                                                180
                                            </span>{" "}
                                            <span className="day"> Dias</span>
                                        </div>
                                    </div>
                                    <div className="div-value-average">
                                        <span>
                                            <i className="bi bi-arrow-right-short me-1"></i>{" "}
                                            <span>Total de</span>{" "}
                                            {props.InfoProducts.qt180d}
                                        </span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="div-days">
                                        <i className="bi bi-calendar3 me-2"></i>{" "}
                                        <div>
                                            <span className="mt-1 number">
                                                365
                                            </span>{" "}
                                            <span className="day"> Dias</span>
                                        </div>
                                    </div>
                                    <div className="div-value-average">
                                        <span>
                                            <i className="bi bi-arrow-right-short me-1"></i>{" "}
                                            <span>Total de</span>{" "}
                                            {props.InfoProducts.qt365d}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                props.LoadingControlModal !== true && (
                    <div className="table-responsive div-main-content">
                        <h5 className="mb-4">Informações de médias</h5>
                        <div className="alert alert-warning mt-4" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                            &nbsp; Não foi possível encontrar as informações de
                            médias.
                        </div>
                    </div>
                )
            )}
        </S.Avarages>
    );
};

export default Avarages;
