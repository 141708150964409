import styled from "styled-components";

export const HomePage = styled.div`
    padding-top: 100px;

    .div-cards-alert {
        margin-bottom: 40px;
    }
    .div-cards {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .div-cards .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid #00b4e5;
        border-radius: 7px;
        margin-right: 15px;
        margin-left: 15px;
        padding: 15px;
        width: 170px;
        height: 170px;
        text-decoration: none;
        color: #00b4e5;
    }

    .div-cards .card:hover {
        color: #fff;
        background-color: #00b4e5;
        transition: 0.3s;
    }

    .div-cards .card i {
        font-size: 60px;
    }
`;
