import styled from "styled-components";

export const OptionsTable = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;

    .div-opcoes-tabela-direita {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .div-opcoes-tabela-esquerda {
        display: flex;
        flex-direction: row;
        justify-content: left;
        width: 100%;
        margin-bottom: 15px;
    }

    .div-opcoes-tabela-esquerda .select-qt-linhas {
        width: auto;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;

        .div-opcoes-tabela-esquerda {
            width: 50%;
            margin-bottom: 0;
        }

        .div-opcoes-tabela-direita {
            justify-content: right;
            width: 50%;
        }
    }
`;
