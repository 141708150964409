import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

const SideBarAreaLog = (props) => {
    let optionHome = "";
    let optionMyData = "";
    let optionDoctors = "";
    let optionPatients = "";
    let optionOrders = "";

    //Verifica a página atual
    if (props.page === "home") {
        optionHome = "active-option";
    }

    if (props.page === "myData" || props.page === "myDataRegister") {
        optionMyData = "active-option";
    }

    if (props.page === "doctors" || props.page === "doctorRegister") {
        optionDoctors = "active-option";
    }

    if (props.page === "patients" || props.page === "patientRegister") {
        optionPatients = "active-option";
    }

    if (props.page === "orders" || props.page === "orderRegister") {
        optionOrders = "active-option";
    }

    optionHome = "active-option";

    return (
        <S.SideBarAreaLog>
            <nav>
                <Link aria-current="page" to="/areaLog">
                    <span className={optionHome}>
                        <i className="bi bi-1-circle"></i>
                        <span>Opção 1</span>
                    </span>
                </Link>

                <Link to="/areaLog">
                    <span className={optionMyData}>
                        <i className="bi bi-2-circle"></i>
                        <span>Opção 2</span>
                    </span>
                </Link>

                <Link to="/areaLog">
                    <span className={optionDoctors}>
                        <i className="bi bi-3-circle"></i>
                        <span>Opção 3</span>
                    </span>
                </Link>

                <Link to="/areaLog">
                    <span className={optionPatients}>
                        <i className="bi bi-4-circle"></i>
                        <span>Opção 4</span>
                    </span>
                </Link>

                <Link to="/areaLog">
                    <span className={optionOrders}>
                        <i className="bi bi-5-circle"></i>
                        <span>Opção 5</span>
                    </span>
                </Link>
            </nav>
        </S.SideBarAreaLog>
    );
};

export default SideBarAreaLog;
