import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    apiBase,
    createSession,
    destroySession,
    getInfoUser,
    checkTokenValidity,
} from "../services/api";

export const AuthContext = createContext();

//"children" é todo o conteudo que fica dentro AuthProvider
export const AuthProvider = ({ children }) => {
    const [User, setUser] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    //Recuperando as informações do usuário logado no Local Storage
    useEffect(() => {
        const recoveredInfoUser = localStorage.getItem("userInfo");
        const token = localStorage.getItem("token");

        if (token && recoveredInfoUser) {
            const tokenExpired = checkTokenValidity();

            if (tokenExpired) {
                localStorage.removeItem("userInfo");
                localStorage.removeItem("token");
                apiBase.defaults.headers.Authorization = null;
                setUser(null);
                navigate("/login");
            } else {
                //Recuperando informações do usuário
                setUser(JSON.parse(recoveredInfoUser));
                //Informando ao Axios que apartir desse momento é para mandar o token nas próximas requisições
                apiBase.defaults.headers.Authorization = `Bearer ${token}`;
                navigate("/");
            }
        }

        setLoading(false);
    }, []);

    //Fazendo processo de login
    const login = async (email, password) => {
        //Validando o usuário na API
        const response = await createSession(email, password);

        let token = "";
        let dataUser = "";
        let userInfo = "";

        //Validando a resposta da API
        if (response.status === 200) {
            //Guardando o token que o usuario recebeu
            token = response.data.access_token;

            //Informando ao Axios que apartir desse momento é para mandar o token nas próximas requisições
            apiBase.defaults.headers.Authorization = `Bearer ${token}`;

            //Recuperando as informações do usuário logado
            dataUser = await getInfoUser(response.data.user_id);

            //Guardando as informações do usuário na variavel loggerUser
            userInfo = { name: dataUser.data.nome, email: email };

            //Guardando o token no local Storeage
            localStorage.setItem("token", token);

            //Guardando as informações do usuário do usuário no local Storeage

            localStorage.setItem("userInfo", JSON.stringify(userInfo));

            //Informando que o usário agora está logado
            setUser(userInfo);

            //Redirecionando para página home
            navigate("/");
        } else {
            return response;
        }
    };

    const logout = async () => {
        //Endpoint que elimina o token atual
        await destroySession();

        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        apiBase.defaults.headers.Authorization = null;
        setUser(null);
        navigate("/login");
    };

    return (
        <AuthContext.Provider
            value={{
                authenticated: !!User,
                User,
                loading,
                login,
                logout,
                setUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
