import React, { useEffect, useState } from "react";
import * as S from "./styles";

const Filters = (props) => {
    const [Branch, setBranch] = useState("");
    const [ProductValidity, setProductValidity] = useState("");
    const [ProductsBlockedLots, setProductsBlockedLots] = useState(false);
    const [OrdersInProduction, setOrdersInProduction] = useState(false);
    const [
        AvailableStockSmallerAverageDay23,
        setAvailableStockSmallerAverageDay23,
    ] = useState(false);
    const [
        PendingInventoryBiggerOrEqualGeneralInventory,
        setPendingInventoryBiggerOrEqualGeneralInventory,
    ] = useState(false);
    const [MinimumQuantityAvailable, setMinimumQuantityAvailable] =
        useState("");
    const [MaximumQuantityAvailable, setMaximumQuantityAvailable] =
        useState("");

    const [ShowBtnAplly, setShowBtnAplly] = useState(
        "btn btn-sm btn-success invisible me-2"
    );

    //Adiciona o valor true ou false no objeto de filtros.
    //Se o usuário checou adiciona true se não checgou adiciona false.
    const filterChangeCheckbox = (e) => {
        const { name, checked } = e.target;

        if (checked === true) {
            props.setFilters((Filters) => ({
                ...Filters,
                [name]: checked,
            }));
        }

        if (checked === false) {
            props.setFilters((Filters) => ({
                ...Filters,
                [name]: checked,
            }));
        }
    };

    //Adiciona o valor digitado pelo usuário no objeto de filtros.
    const filterChangeInput = (e) => {
        const { name, value } = e.target;

        if (value !== "") {
            props.setFilters((Filters) => ({
                ...Filters,
                [name]: value,
            }));
        }

        if (value === "") {
            props.setFilters((Filters) => ({
                ...Filters,
                [name]: "",
            }));
        }
    };

    //Coloca os valores padrões no objeto de Filters.
    const clearFilter = () => {
        let filterName = Object.keys(props.Filters);
        let filterValues = Object.values(props.Filters);

        for (let i = 0; i < filterName.length; i++) {
            if (filterValues[i] === true || filterValues[i] === false) {
                props.setFilters((Filters) => ({
                    ...Filters,
                    [filterName[i]]: false,
                }));
            } else {
                props.setFilters((Filters) => ({
                    ...Filters,
                    [filterName[i]]: "",
                }));
            }
        }

        props.setLoading(false);
    };

    //Procura no objeto "Filters" algum status true
    useEffect(() => {
        //console.log("Filters mudou!!!");

        let filterStatus = Object.values(props.Filters);

        for (let i = 0; i < filterStatus.length; i++) {
            if (
                filterStatus[i] === true ||
                (filterStatus[i] !== true &&
                    filterStatus[i] !== false &&
                    filterStatus[i] !== "")
            ) {
                setShowBtnAplly("btn btn-success visible");

                break;
            } else {
                setShowBtnAplly("btn btn-success invisible");
            }
        }

        //console.log(filterStatus);
    }, [props.Filters]);

    return (
        <S.Filters>
            <div className="div-filters-header">
                <h1>
                    <i className="bi bi-calendar3 me-1"></i> PCM
                </h1>
                <div className="div-btn-filters">
                    <button
                        className="btn btn-sm btn-light"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                    >
                        Filtros<i className="bi bi-filter ms-2"></i>
                    </button>
                </div>
            </div>

            <div
                className="offcanvas offcanvas-end"
                data-bs-scroll="true" /* Mesmo com menu lateral ativo é possivel realizar o Scroll na página principal */
                data-bs-backdrop="false"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filtros <i className="bi bi-filter ms-1"></i>
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <form>
                        <div className="mb-4 div-filters">
                            <h6 className="mb-3 filters-title">
                                <i className="bi bi-cloud-fill me-1"></i>{" "}
                                Consultas Remotas
                            </h6>

                            <div className="mb-3">
                                <button
                                    className="btn btn-sm btn-primary w-100"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.setLoading(true);
                                        props.updateProducts();
                                    }}
                                >
                                    <i className="bi bi-repeat me-2"></i>{" "}
                                    Atualizar dados
                                </button>
                            </div>
                        </div>

                        <div className="mb-4 div-filters">
                            <h6 className="mb-4 filters-title">
                                <i className="bi bi-database-fill me-1"></i>
                                Consultas Locais
                            </h6>
                            <div className="">
                                <div className="mb-3">
                                    <select
                                        className="form-select form-select-sm"
                                        aria-label=".form-select-lg example"
                                        name="branch"
                                        value={Branch}
                                        onChange={(e) => {
                                            setBranch(e.currentTarget.value);
                                            filterChangeInput(e);
                                        }}
                                    >
                                        <option value="">Filial</option>
                                        <option value="6">Filial 6</option>
                                        <option value="7">Filial 7</option>
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="availableStockSmallerAverageDay23"
                                            name="availableStockSmallerAverageDay23"
                                            checked={
                                                AvailableStockSmallerAverageDay23
                                            }
                                            onChange={(e) => {
                                                setAvailableStockSmallerAverageDay23(
                                                    e.currentTarget.checked
                                                );
                                                filterChangeCheckbox(e);
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="availableStockSmallerAverageDay23"
                                        >
                                            {
                                                "Disponível menor que Média dia (M23)."
                                            }
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="pendingInventoryBiggerOrEqualGeneralInventory"
                                            name="pendingInventoryBiggerOrEqualGeneralInventory"
                                            checked={
                                                PendingInventoryBiggerOrEqualGeneralInventory
                                            }
                                            onChange={(e) => {
                                                setPendingInventoryBiggerOrEqualGeneralInventory(
                                                    e.currentTarget.checked
                                                );
                                                filterChangeCheckbox(e);
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="pendingInventoryBiggerOrEqualGeneralInventory"
                                        >
                                            {
                                                "Pendente maior ou igual ao Estoque Geral."
                                            }
                                        </label>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <select
                                        className="form-select form-select-sm"
                                        aria-label=".form-select-lg example"
                                        name="productValidity"
                                        value={ProductValidity}
                                        onChange={(e) => {
                                            setProductValidity(
                                                e.currentTarget.value
                                            );
                                            filterChangeInput(e);
                                        }}
                                    >
                                        <option value="">
                                            Validade do Produto
                                        </option>
                                        <option value="30">
                                            Validade 30 Dias
                                        </option>
                                        <option value="60">
                                            Validade 60 Dias
                                        </option>
                                        <option value="90">
                                            Validade 90 Dias
                                        </option>
                                        <option value="120">
                                            Validade 120 Dias
                                        </option>
                                        <option value="180">
                                            Validade 180 Dias
                                        </option>
                                        <option value="365">
                                            Validade 365 Dias
                                        </option>
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="productsBlockedLots"
                                            name="productsBlockedLots"
                                            checked={ProductsBlockedLots}
                                            onChange={(e) => {
                                                setProductsBlockedLots(
                                                    e.currentTarget.checked
                                                );
                                                filterChangeCheckbox(e);
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="productsBlockedLots"
                                        >
                                            Produtos com lotes bloqueados.
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="ordersInProduction"
                                            name="ordersInProduction"
                                            checked={OrdersInProduction}
                                            onChange={(e) => {
                                                setOrdersInProduction(
                                                    e.currentTarget.checked
                                                );
                                                filterChangeCheckbox(e);
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="ordersInProduction"
                                        >
                                            Produtos com ordens em produção.
                                        </label>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label
                                        id="disponivelMaiorQue"
                                        className="form-label"
                                    >
                                        Quantidade Disponínel
                                    </label>
                                    <div className="row g-3">
                                        <div className="col">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                value={MinimumQuantityAvailable}
                                                name="minimumQuantityAvailable"
                                                placeholder="Min"
                                                aria-label="Min"
                                                onChange={(e) => {
                                                    setMinimumQuantityAvailable(
                                                        e.currentTarget.value
                                                    );
                                                    filterChangeInput(e);
                                                }}
                                            />
                                        </div>
                                        <div className="col">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                value={MaximumQuantityAvailable}
                                                name="maximumQuantityAvailable"
                                                placeholder="Max"
                                                aria-label="Max"
                                                onChange={(e) => {
                                                    setMaximumQuantityAvailable(
                                                        e.currentTarget.value
                                                    );
                                                    filterChangeInput(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <button
                                className="btn btn-danger me-2"
                                type="reset"
                                onClick={(e) => {
                                    e.preventDefault();
                                    clearFilter();
                                    props.setLoading(true);
                                    props.setClearFilter(true);
                                    setBranch("");
                                    setProductValidity("");
                                    setProductsBlockedLots(false);
                                    setOrdersInProduction(false);
                                    setAvailableStockSmallerAverageDay23(false);
                                    setPendingInventoryBiggerOrEqualGeneralInventory(
                                        false
                                    );
                                    setMinimumQuantityAvailable("");
                                    setMaximumQuantityAvailable("");
                                }}
                            >
                                <i className="bi bi-trash"></i>
                            </button>

                            <button
                                className={ShowBtnAplly}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.setLoading(true);
                                    props.setProducts(props.ProductsBase);
                                    props.setFilterAplly(true);
                                }}
                            >
                                <i className="bi bi-check-square"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </S.Filters>
    );
};

export default Filters;
