import styled from "styled-components";

export const Loading = styled.div`
    z-index: 1;
    visibility: visible;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffffb0;
    transition: all 0.4s;

    .spinner-grow {
        width: 3rem;
        height: 3rem;
    }
`;
